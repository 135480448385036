import React from 'react';
import Images from '../utils/Images';
import './SquareContainer.scss';

// eslint-disable-next-line react/prop-types
const SquareContainer = ({ children, backgroundImage }) => {
  return (
    <div
      className="container--square"
      style={
        backgroundImage && { backgroundImage: `url("${Images.square(backgroundImage, 600)}")` }
      }
    >
      <div>{children}</div>
    </div>
  );
};

export default SquareContainer;
