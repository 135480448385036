/* eslint-disable operator-linebreak */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTranslations(prefix) {
  const { t: rawT } = useTranslation();
  const t = useMemo(() => (prefix ? (key, data) => rawT(`${prefix}.${key}`, data) : rawT), [
    prefix,
    rawT,
  ]);
  return { t };
}
