/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Form } from 'semantic-ui-react';

import useTranslations from '../../hooks/useTranslations';
import { momentTz } from '../../utils/date';
import { replaceValues } from '../../utils/stringUtils';
import UploadFileBlock from './UploadFileBlock';

function sanitizeSelectValue(field, value) {
  if (field.multiple) {
    if (!value) return null;
    // eslint-disable-next-line no-param-reassign
    if (!Array.isArray(value)) value = [value];
    if (!value.length) return null;
    return value;
  }
  if (Array.isArray(value)) return value[0];
  return value;
}

const FieldInput = ({ field, label, value, onChange, editable, error }) => {
  const { t } = useTranslations();
  const {
    key,
    type = 'string',
    hidden = false,
    itemTemplate,
    dateFormat = 'YYYY-MM-DD HH:mm',
    label: fieldLabel,
  } = field;
  if (!editable) {
    if (hidden) {
      return null;
    }

    switch (type) {
      case 'array': {
        if (!value.length) {
          return <>{t('profile.no-information-to-display')}</>;
        }
        if (itemTemplate) {
          return (
            <ul>
              {value.map((v) => {
                const finalString = replaceValues(itemTemplate, v);
                return <li>{finalString}</li>;
              })}
            </ul>
          );
        }
        return <>{value.join(', ')}</>;
      }
      case 'date': {
        const formatedDate = momentTz(value).format(dateFormat);
        return <>{formatedDate}</>;
      }
      case 'file': {
        return <UploadFileBlock fieldKey={key} value={value} />;
      }
      default: {
        return <>{label}</>;
      }
    }
  }

  switch (type) {
    case 'bool': {
      return (
        <Form.Checkbox
          toggle
          label={fieldLabel}
          checked={value || false}
          onChange={(_, { checked }) => onChange({ [key]: checked })}
        />
      );
    }
    case 'select': {
      return (
        <Form.Select
          fluid
          label={fieldLabel}
          options={field.options}
          multiple={field.multiple || false}
          selectOnBlur={false}
          placeholder={field.placeholder}
          search={field.typeOptions?.search}
          error={error || (field.required && (!value || !value.length))}
          value={sanitizeSelectValue(field, value) ?? (field.multiple ? [] : '')}
          onChange={(_, { value: v }) => onChange({ [key]: sanitizeSelectValue(field, v) })}
        />
      );
    }
    case 'array': {
      //if array no update
      console.warn('not yet implemented');
      return null;
    }
    case 'date': {
      console.warn('not yet implemented');
      return null;
    }
    case 'file': {
      return <UploadFileBlock fieldKey={key} onChange={onChange} value={value} />;
    }
    case 'string':
    default: {
      return (
        <Form.Input
          value={value || ''}
          fluid
          label={fieldLabel}
          error={error}
          onChange={(_, { value: v }) => onChange({ [key]: v })}
        />
      );
    }
  }
};

export default FieldInput;
