import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { getParameterByName } from '../../../../../../shared/Store';

export function replaceValuesWithQS(text) {
  return text.replace(/{(.*?)}/g, (match, key) => getParameterByName(key) || '');
}

// { type: 'action', variant: 'button', text: 'Inscription', url: '/inscription' }
const ActionBlock = ({ color, variant, text, url, target, ...props }) => {
  return (
    <Button
      primary={color === 'primary'}
      className={variant === 'link' ? 'button--link' : undefined}
      style={variant === 'link' ? { color } : undefined}
      as="a"
      target={target}
      {...props}
      href={replaceValuesWithQS(url)}
    >
      {text}
    </Button>
  );
};

ActionBlock.defaultProps = {
  color: undefined,
  target: undefined,
  variant: 'button',
};

ActionBlock.propTypes = {
  color: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['button', 'primary', 'link']),
};

export default ActionBlock;
