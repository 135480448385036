import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import FormModal from './FormModal';
import './FormModal.scss';

// { type: 'action', variant: 'button', text: 'Inscription', url: '/inscription' }
const RegisterButtonBlock = ({ color, variant, text, form, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        primary={color === 'primary'}
        className={variant === 'link' ? 'button--link' : undefined}
        style={variant === 'link' ? { color } : undefined}
        {...props}
        onClick={() => setShowModal(true)}
      >
        {text}
      </Button>
      {form && showModal && <FormModal form={form} onClose={() => setShowModal(false)} />}
    </>
  );
};

RegisterButtonBlock.defaultProps = {
  color: undefined,
  form: undefined,
  target: undefined,
  variant: 'button',
};

RegisterButtonBlock.propTypes = {
  color: PropTypes.string,
  form: PropTypes.shape({ fields: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })) }),
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['button', 'primary', 'link']),
};

export default RegisterButtonBlock;
