import PropTypes from 'prop-types';
import React from 'react';
import './HtmlBlock.scss';

function HtmlBlock({ content, as: Component, className }) {
  if (!content) return null;
  return (
    <div className="login-block--html">
      <Component className={className} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

HtmlBlock.defaultProps = {
  as: 'div',
  className: undefined,
};

HtmlBlock.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default HtmlBlock;
