import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import omitBy from 'lodash/fp/omitBy';
import fromPairs from 'lodash/fromPairs';
import throttle from 'lodash/throttle';
import toPairs from 'lodash/toPairs';
import { useCallback, useEffect, useState } from 'react';

function filterEntries(filter, maxCount, entries, calculateMoreItem, searchValue) {
  return flow(
    toPairs,
    map(([resource, entry]) => {
      const items = entry.results.filter((item) => filter(item, searchValue));
      const shouldShowMore = items.length > maxCount;
      const moreItem = calculateMoreItem({ resource, searchValue });
      const results =
        shouldShowMore && moreItem ? items.slice(0, maxCount - 1).concat(moreItem) : items;

      return [
        resource,
        {
          ...entry,
          results,
        },
      ];
    }),
    fromPairs,
    omitBy(flow(get('results'), isEmpty)),
  )(entries);
}

export function useGlobalSearch(
  entries,
  { searchFilter, prepareSearchValue, moreItem, maxResultsByEntity },
) {
  const [searchField, setSearchField] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});

  const updateSearch = useCallback(
    throttle((searchValue, allEntries, filter, maxCount, moreItemCreator) => {
      setLoading(false);
      const preparedSearchValue = prepareSearchValue(searchValue);
      setResults(filterEntries(filter, maxCount, allEntries, moreItemCreator, preparedSearchValue));
    }, 200),
    [],
  );

  useEffect(() => {
    if (!searchField) {
      setResults({});
      return;
    }
    setLoading(true);
    updateSearch(
      searchField,
      entries,
      (item, search) => searchFilter(search, item),
      maxResultsByEntity,
      moreItem,
    );
  }, [searchField, entries]);

  // console.log('results', results);

  return { searchField, setSearchField, loading, results };
}
