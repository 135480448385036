import React from 'react';
import get from 'lodash/get';
import { bem } from '../../../core/design/bem';
import {
  SuggestedWorkshopsCarousel,
  SuggestedWorkshopsList,
} from '../../../workshops/blocks/CollectionAndCategoryWorkshops';
import BlockContainer from '../../BlockContainer';

import { BlockProps, ContainerProps } from '../types';
import { useCollectionAndCategoryWorkshops } from '../../../workshops/store/workshops.hooks';

const css = bem('CategoryWorkshopsBlock');

type CategoryWorkshopsBlockProps = BlockProps & {
  category: string;
  className?: string;
  collection: string;
  container?: ContainerProps;
  variant?: 'carousel' | 'list';
} & typeof CategoryWorkshopsBlockDefaultProps;

const CategoryWorkshopsBlockDefaultProps = {
  container: {},
  className: '',
  variant: 'carousel',
};

const components = {
  list: SuggestedWorkshopsList,
  carousel: SuggestedWorkshopsCarousel,
};

const CategoryWorkshopsBlock = ({
  _id,
  category,
  collection,
  className,
  container,
  variant,
  ...rest
}: CategoryWorkshopsBlockProps): JSX.Element => {
  const workshops = useCollectionAndCategoryWorkshops(collection, category);

  const Component = get(components, variant, SuggestedWorkshopsList);
  return (
    <BlockContainer
      {...container}
      header={variant === 'carousel' ? '' : container.header}
      className={css({
        id: _id,
      }).mix(className)}
    >
      <Component {...rest} container={container} workshops={workshops || []} />
    </BlockContainer>
  );
};

CategoryWorkshopsBlock.defaultProps = CategoryWorkshopsBlockDefaultProps;

export default CategoryWorkshopsBlock;
