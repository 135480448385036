import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMedia } from 'react-media';
import { Dropdown, Search } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import SearchCategoryLayout from './SearchCategoryLayout';
import './SearchRenderer.scss';
import SearchResult from './SearchResult';

const css = bem('SearchRenderer');

const SearchRenderer = ({
  loading,
  entries: results,
  onSearchChange,
  maxResultsByEntity,
  value,
  searchProps,
}) => {
  const [opened, setOpened] = useState(false);

  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  if (mobile) {
    return (
      <Dropdown
        search
        fluid
        icon="search"
        placeholder="Search"
        selection
        labeled
        clearable
        className="SearchRenderer"
        scrolling
        loading={loading}
        value={value}
        searchQuery={value}
        open={opened}
        onClick={() => {
          setOpened(true);
        }}
        onBlur={() => setOpened(false)}
        onFocus={() => setOpened(true)}
        onChange={(event, data) => {
          onSearchChange(data.value);
        }}
        onSearchChange={(event, data) => {
          setOpened(true);
          onSearchChange(data.searchQuery.toLowerCase());
        }}
      >
        <Dropdown.Menu>
          {Object.entries(results).map(([key, { name, results: categoryResults }]) => (
            <SearchCategoryLayout key={key} name={name}>
              {categoryResults.slice(0, maxResultsByEntity).map((item) => (
                <SearchResult key={item.key} {...item} onSelect={() => setOpened(false)} />
              ))}
            </SearchCategoryLayout>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Search
      className={css({ hasValue: !!value }).toString()}
      category
      fluid
      loading={loading}
      results={results}
      onSearchChange={(event, data) => onSearchChange(data.value.toLowerCase())}
      resultRenderer={SearchResult}
      value={value}
      {...searchProps}
    />
  );
};

SearchRenderer.defaultProps = {
  searchProps: undefined,
};

SearchRenderer.propTypes = {
  loading: PropTypes.bool.isRequired,
  entries: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.node.isRequired,
        description: PropTypes.node,
        image: PropTypes.string,
      }),
    ),
  ).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  maxResultsByEntity: PropTypes.number.isRequired,
  searchProps: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export default SearchRenderer;
