import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import React from 'react';
import { useGlobalSearch } from './search.hooks';
import SearchRenderer from './SearchRenderer';

function SearchBar({
  entries,
  searchFilter,
  maxResultsByEntity,
  className,
  moreItem,
  prepareSearchValue,
  searchProps,
}) {
  const { searchField, setSearchField, loading, results } = useGlobalSearch(entries, {
    searchFilter,
    prepareSearchValue,
    moreItem,
    maxResultsByEntity,
  });

  // console.log('search', searchField, results);

  return (
    <div className={className}>
      <SearchRenderer
        loading={loading}
        entries={results}
        onSearchChange={setSearchField}
        maxResultsByEntity={maxResultsByEntity}
        searchProps={searchProps}
        value={searchField}
      />
    </div>
  );
}
const titleFilter = (value, { title = '' }) => title.includes(value);
const defaultPrepare = (value) => deburr(value.toLocaleString());

SearchBar.defaultProps = {
  searchFilter: titleFilter,
  prepareSearchValue: defaultPrepare,
  maxResultsByEntity: 3,
  className: undefined,
  moreItem: () => null,
  searchProps: undefined,
};
const searchItem = PropTypes.shape({
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  image: PropTypes.string,
});
SearchBar.propTypes = {
  entries: PropTypes.objectOf(PropTypes.arrayOf(searchItem)).isRequired,
  searchFilter: PropTypes.func,
  maxResultsByEntity: PropTypes.number,
  className: PropTypes.string,
  moreItem: PropTypes.func,
  prepareSearchValue: PropTypes.func,
  searchProps: PropTypes.object,
};

export default SearchBar;
