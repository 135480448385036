import React, { FC, useState } from 'react';
import { bem } from '../../core/design/bem';
// eslint-disable-next-line import/no-cycle
import { CmsBlocks } from '../cms';
import { BlockProps } from '../cms/types';
import './GridLayout.scss';

export type GridLayoutItem = {
  gridArea: string;
} & BlockProps;

export type GridLayoutTemplate = {
  gap: string;
  columns?: string[];
  rows?: string[];
  templateAreas: string[][];
};

export type GridLayoutProps = {
  blocks: GridLayoutItem[];
  defaultLayout?: string;
  layouts: Record<string, GridLayoutTemplate>;
};

type LayoutContextType = {
  layout: string;
  setLayout: (layout: string) => void;
};

const css = bem('GridLayout');

export const LayoutContext = React.createContext({} as LayoutContextType);

export const GridLayout: FC<GridLayoutProps> = (props: GridLayoutProps) => {
  const { blocks, defaultLayout = 'default', layouts } = props;
  const [layout, setLayout] = useState(defaultLayout);
  const currentLayout = layouts?.[layout];

  if (!currentLayout) {
    // eslint-disable-next-line no-console
    console.warn('Missing GridLayout layout', layout);
    return null;
  }

  return (
    <div
      className={css({ layout })}
      style={{
        gap: currentLayout.gap,
        gridTemplateColumns: currentLayout.columns?.join(' '),
        gridTemplateRows: currentLayout.rows?.join(' '),
        gridTemplateAreas: currentLayout.templateAreas.map((row) => `"${row.join(' ')}"`).join(' '),
      }}
    >
      <LayoutContext.Provider value={{ layout, setLayout }}>
        {blocks.map((block) => {
          const { gridArea } = block;
          return (
            <div key={gridArea} style={{ gridArea }}>
              <CmsBlocks blocks={[block]} />
            </div>
          );
        })}
      </LayoutContext.Provider>
    </div>
  );
};

export default GridLayout;
