import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const PreviewContext = React.createContext(false);
export const useIsPreview = () => useContext(PreviewContext);
export const PreviewMode = ({ children }) => {
  // eslint-disable-next-line
  return <PreviewContext.Provider value={true}>{children}</PreviewContext.Provider>;
};
PreviewMode.propTypes = {
  children: PropTypes.element.isRequired,
};
