import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useDesignConfig } from '../config/design.context';

const ProgressCircular = ({ value, config }) => {
  const design = useDesignConfig();
  const { primaryColor = 'black' } = design;
  return (
    <CircularProgressbar
      value={value}
      text={`${value}%`}
      styles={{
        path: { stroke: primaryColor },
        trail: { stroke: 'transparent' },
        text: { fontWeight: 'bold', fontSize: 28, fill: 'black' },
        ...config.progress,
      }}
    />
  );
};

ProgressCircular.defaultProps = {
  config: {},
  value: undefined,
};

ProgressCircular.propTypes = {
  config: PropTypes.object,
  value: PropTypes.number,
};

export default ProgressCircular;
