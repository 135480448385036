/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Item, Button } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import EntityLink from '../../components/EntityLink';
import useTranslations from '../../hooks/useTranslations';
import ProgressCircular from '../../components/ProgressCircular';
import { replaceValues } from '../../utils/stringUtils';
import { useExhibitorOpportunities } from '../../matching/store/matching.hooks';
import './OpportunityListBlock.scss';

const cxRoot = bem('opportunity-list-block');
const cxEntry = bem(cxRoot('entry').toString());
const cxEntryContent = bem(cxEntry('content').toString());

const OpportunityEntry = ({
  opportunity,
  title: titleTemplate,
  subtitle: subtitleTemplate,
  percentage: percentageTemplate,
  progress: progressConfig,
}) => {
  const title = replaceValues(titleTemplate, opportunity);
  const subtitle = replaceValues(subtitleTemplate, opportunity);
  const percentage = replaceValues(percentageTemplate, opportunity);

  return (
    <Item as={EntityLink} entity={{ ...opportunity, kind: 'offer' }} className={cxEntry()}>
      {percentage != null && (
        <div className={cxEntry('progress')}>
          <ProgressCircular value={percentage} config={{ progress: progressConfig }} />
        </div>
      )}
      <div className={cxEntryContent()}>
        <div className={cxEntryContent('title')}>{title}</div>
        <div className={cxEntryContent('subtitle')}>{subtitle}</div>
      </div>
    </Item>
  );
};

OpportunityEntry.defaultProps = {
  title: '{title}',
  subtitle: '{subtitle}',
  percentage: '{percentage}',
  progress: undefined,
};

OpportunityEntry.propTypes = {
  opportunity: PropTypes.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  percentage: PropTypes.string,
  progress: PropTypes.object,
};

const OpportunityListBlock = ({
  item,
  opportunity: opportunityConfig,
  scoreCriteria,
  header,
  headerKey,
  subtitleKey,
  maxInitialDisplay,
  translationPrefix,
}) => {
  const { t } = useTranslations();

  const [isDisplayAll, setDisplayAll] = useState(false);
  const opportunities = useExhibitorOpportunities(item?.collection, item?._id, scoreCriteria);
  if (!opportunities.length) return null;

  const shownOpportunities = isDisplayAll
    ? opportunities
    : opportunities.slice(0, maxInitialDisplay);

  const remainingCount = opportunities.length - shownOpportunities.length;
  const hasMoreToShow = remainingCount > 0;

  return (
    <Segment className={cxRoot()}>
      <Header as="h3">
        {header || t(`${translationPrefix}.${headerKey}`, { count: opportunities.length })}
      </Header>
      <div className={cxRoot('subtitle')}>
        {t(`${translationPrefix}.${subtitleKey}`, { count: opportunities.length })}
      </div>
      {shownOpportunities.map((opportunity) => {
        return <OpportunityEntry {...opportunityConfig} opportunity={opportunity} />;
      })}
      {hasMoreToShow && (
        <div className={cxRoot('view-more')}>
          <Button onClick={() => setDisplayAll(true)}>
            {t(`${translationPrefix}.opportunities-view-more`, { count: remainingCount })}
          </Button>
        </div>
      )}
    </Segment>
  );
};

OpportunityListBlock.defaultProps = {
  item: undefined,
  opportunity: undefined,
  header: undefined,
  headerKey: 'opportunities',
  subtitleKey: 'opportunities-subtitle',
  scoreCriteria: 'positive',
  translationPrefix: 'sponsors.sponsor',
  maxInitialDisplay: 3,
};

OpportunityListBlock.propTypes = {
  item: PropTypes.shape({
    collection: PropTypes.string,
    _id: PropTypes.string,
  }),
  opportunity: PropTypes.object,
  header: PropTypes.string,
  headerKey: PropTypes.string,
  subtitleKey: PropTypes.string,
  scoreCriteria: PropTypes.string,
  translationPrefix: PropTypes.string,
  maxInitialDisplay: PropTypes.number,
};

export default OpportunityListBlock;
