import React from 'react';
// eslint-disable-next-line import/no-cycle
import { CmsBlocks } from '../../cms/CmsScreen';
import { bem } from '../../core/design/bem';
import BlockContainer from '../BlockContainer';
import { BlockProps, ContainerProps } from './types';

const containerBlock = bem('block--container');

type BlockWrapperProps = BlockProps & {
  container?: ContainerProps;
  blocks: any[];
};

const BlockWrapper = ({ _id, container, blocks }: BlockWrapperProps): JSX.Element => (
  <BlockContainer
    className={containerBlock({ [_id]: true }).toString()}
    type="segment"
    {...container}
  >
    <CmsBlocks blocks={blocks} />
  </BlockContainer>
);

BlockWrapper.defaultProps = {
  container: {},
};

export default BlockWrapper;
