import React from 'react';
import store from '../../../shared/Store';
import { fetchUser } from '../../../store/actions';
import { useMe } from '../../hooks';
import ResetPasswordModal from '../ResetPasswordModal';

const CheckMustResetPassword = (): JSX.Element | null => {
  const user = useMe();
  if (!user?.mustUpdatePassword) return null;
  function handleRefresh() {
    // Will refresh the user and remove the mustRefreshPassword flag :)
    store.reduxStore.dispatch(fetchUser());
  }
  return (
    <ResetPasswordModal canCancel={false} requireCurrentPassword={false} onClose={handleRefresh} />
  );
};

export default CheckMustResetPassword;
