import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input } from 'semantic-ui-react';
import UserBubble from '../../../../components/UserBubble';

const translationPrefix = 'workshops.workshop.comments';

export type CommentInputProps = {
  onSend: (message: string) => Promise<boolean>;
  isAnonymous: boolean;
  maxChars?: number;
};

const CommentInput = ({ onSend, maxChars, isAnonymous }: CommentInputProps): JSX.Element => {
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  async function handleSend() {
    const success = await onSend(message);
    if (success) {
      setMessage('');
    }
  }
  return (
    <div className="comment--user">
      <div className="comment--container">
        <UserBubble isAnonymous={isAnonymous} />
        <Input
          className="comment--input"
          name="comment"
          value={message}
          placeholder={t(`${translationPrefix}.placeholder`)}
          onChange={(e) =>
            setMessage(maxChars ? (e.target.value || '').slice(0, maxChars) : e.target.value)
          }
        />
      </div>
      <div className="comment--action">
        {maxChars && (
          <div className="comment--send--button">
            {message.length || 0} / {maxChars}
          </div>
        )}
        <Button
          primary
          disabled={!message}
          icon
          labelPosition="right"
          className="comment--send--button"
          onClick={handleSend}
        >
          <Icon name="send" />
          {t(`${translationPrefix}.comment`)}
        </Button>
      </div>
    </div>
  );
};

export default CommentInput;
