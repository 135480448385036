import React from 'react';
import { Input, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { bem } from '../../core/design/bem';
import './SocialNetworkModal.scss';
import { getString } from '../../utils';

const translationPrefix = 'components.social-network-modal';
const css = bem('SocialNetworkModal');
const shareButton = bem(css('shareButton').toString());

type SocialNetworkModalProps = {
  onClose: () => void;
  onClick: (type: string) => void;
  shareTitle: string;
  shareUrl: string;
  iconSize?: number;
  hashtag?: string;
  hashtags?: Array<string>;
};

const SocialNetworkModalDefaultProps = {
  iconSize: 47,
  hashtag: undefined,
  hashtags: undefined,
};

const SocialNetworkModal = ({
  shareTitle,
  shareUrl,
  iconSize = 47,
  hashtag,
  hashtags,
  onClose,
  onClick,
}: SocialNetworkModalProps): React.ReactElement | null => {
  const handleCopy = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(shareUrl);
    onClick('clipboard');
    toast(getString(`${translationPrefix}.copied-to-clipboard`));
  };

  return (
    <Modal className={css().toString()} open size="tiny" onClose={onClose} closeIcon>
      <Modal.Header as="h3" className={css('header').toString()}>
        {shareTitle}
      </Modal.Header>
      <Modal.Content className={css('content').toString()}>
        <span>{getString(`${translationPrefix}.share`)}</span>
        <div className={css('sharingButtons', { container: true }).toString()}>
          <TwitterShareButton
            className={shareButton({ twitter: true }).toString()}
            hashtags={hashtags}
            onClick={() => {
              onClick('twitter');
            }}
            title={shareTitle}
            url={shareUrl}
          >
            <TwitterIcon size={iconSize} round />
            <span>Twitter</span>
          </TwitterShareButton>

          <LinkedinShareButton
            className={shareButton({ linkedin: true }).toString()}
            onClick={() => {
              onClick('linkedin');
            }}
            title={shareTitle}
            url={shareUrl}
          >
            <LinkedinIcon size={iconSize} borderRadius={10} />
            <span>Linkedin</span>
          </LinkedinShareButton>

          <FacebookShareButton
            url={shareUrl}
            hashtag={hashtag}
            className={shareButton({ facebook: true }).toString()}
            onClick={() => {
              onClick('facebook');
            }}
          >
            <FacebookIcon size={iconSize} round bgStyle={{ fill: '#1777F2' }} />
            <span>Facebook</span>
          </FacebookShareButton>
        </div>
        <Input
          className={css('copyInput', { container: true }).toString()}
          icon="copy outline"
          readOnly
          value={shareUrl}
          onClick={handleCopy}
        />
      </Modal.Content>
    </Modal>
  );
};

SocialNetworkModal.defaultProps = SocialNetworkModalDefaultProps;

export default SocialNetworkModal;
