import { useScreenConfig } from '../config/screens.context';
import { InviteModes } from './InviteModes';

export const DEFAULT_INVITE_MODE = InviteModes.REQUEST;

export const useInviteMode = () => {
  const { inviteMode = DEFAULT_INVITE_MODE } = useScreenConfig('networking');
  return inviteMode;
};

export const getInviteMode = () => {
  const screenId = 'networking';
  return window.__DATA__?.screens?.[screenId]?.inviteMode ?? DEFAULT_INVITE_MODE;
};
