import Handlebars from 'handlebars';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { useMe } from '../profile/hooks';

Handlebars.registerHelper('get', (path, opts) => get(opts, `data.root.${path}`));

Handlebars.registerHelper('ifEquals', (arg1, arg2, options) => {
  // eslint-disable-next-line eqeqeq
  return arg1 == arg2 ? options.fn(this) : options.inverse(this);
});

const HtmlTemplate = ({ as: Component, item, template, ...props }) => {
  const user = useMe();
  if (!template) return null;
  // Unescape quotes, breaks templating otherwise ({{#ifEquals get(user, "key")}})
  const render = Handlebars.compile(template.replace(/&quot;/g, '"'));
  const html = render({ item, user });
  if (!html) return null;
  return <Component {...props} dangerouslySetInnerHTML={{ __html: html }} />;
};

HtmlTemplate.defaultProps = {
  as: 'p',
  item: undefined,
  template: undefined,
};

HtmlTemplate.propTypes = {
  as: PropTypes.string,
  item: PropTypes.object,
  template: PropTypes.string,
};

export default HtmlTemplate;
