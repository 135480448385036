import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import ChatStatus from './ChatStatus';

const me = (state) => state.user.user;
export const allChats = (state) => state.networking.chats ?? {};
export const allChatsList = (state) => Object.values(allChats(state));

const activeChat = (chat) => chat.status === ChatStatus.accepted;

export const hasActiveChats = (state) => some(allChats(state), activeChat);
export const activeChats = (state) => pickBy(allChats(state), activeChat);
export const activeChatsList = (state) => Object.values(activeChats(state));

const awaitingInvite = (chat) => chat.status === ChatStatus.invited;
const inviteSentToMe = (chat, userId) => chat.invited === userId;
const awaitingInviteSentToMe = (userId) => (chat) =>
  inviteSentToMe(chat, userId) && awaitingInvite(chat);

export const invitesThatIDidntTreat = (state) =>
  allChatsList(state).filter(awaitingInviteSentToMe(me(state)?._id));

export const invitesByStatus = (state) => {
  const chats = allChatsList(state);
  const selfId = me(state)?._id;
  const intermediate = groupBy(chats, (chat) =>
    inviteSentToMe(chat, selfId) ? 'received' : 'sent',
  );

  return {
    received: groupBy(intermediate.received, 'status'),
    sent: groupBy(intermediate.sent, 'status'),
  };
};

export const chatIdFromHash = (hash) => (state) => state.networking?.routeIdsMap?.[hash];
export const chatHashFromId = (chatId) => (state) => state.networking?.chats?.[chatId]?.hash;
