import React from 'react';
import SidebarBlocks from '../../../components/SidebarBlocks';
import blockComponents from './blocks';

const SideSection = ({ sidebar }) => {
  return (
    <SidebarBlocks
      sidebar={sidebar}
      defaultWidth={8}
      blockComponents={blockComponents}
      columnProps={{ className: 'column--auth-sidebar', textAlign: 'left' }}
    />
  );
};

export default SideSection;
