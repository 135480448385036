import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import some from 'lodash/some';
import { updateOrAppendItem } from '../../utils/arrayUtils.ts';
import { SET_ALL, SET_BY_ID } from './workshops.types';

const initialState = {
  workshops: [],
  workshopsById: {},
  rawWorkshop: [],
};

function ensureCollectionAndSlug(w) {
  return {
    ...w,
    kind: 'workshops',
    originalCollection: w.collection,
    slug: w.slug ? w.slug.trim() : w._id,
  };
}

/**
 * Compute new state by generating workshops if needed
 * @param {*} rawWorkshops
 * @returns
 */
function buildStore(rawWorkshops) {
  let workshops = rawWorkshops;
  if (some(rawWorkshops, (w) => w.replayCollection)) {
    workshops = flatten(
      rawWorkshops.map((w) => {
        if (w.replayCollection && w.replays?.length) {
          return [
            { ...w, reference: `vod/${w.slug}` }, // Ugly OVH hack
            {
              ...w,
              _id: `${w._id}-${w.replayCollection}`,
              collection: w.replayCollection,
              comments: { ref: { collection: w.collection, groupId: w._id } },
            },
          ];
        }
        return [w];
      }),
    );
  }
  return { rawWorkshops, workshops, workshopsById: keyBy(workshops, '_id') };
}

export default function reduceWorkshops(state = initialState, action) {
  switch (action.type) {
    case SET_ALL: {
      return buildStore(action.workshops.map(ensureCollectionAndSlug));
    }
    case SET_BY_ID: {
      // Update a given sponsor
      const workshops = updateOrAppendItem(
        state.rawWorkshops,
        ensureCollectionAndSlug(action.workshop),
      );
      return buildStore(workshops);
    }
    default:
      return state;
  }
}
