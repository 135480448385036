/* eslint-disable no-restricted-globals */
import noop from 'lodash/noop';

/**
 * Load a remote script.
 * Id is used to prevent multiple load of the same script.
 * @param {string} src
 * @param {string} id
 * @returns {Promise<void>}
 */
function loadScript(src, id) {
  if (id && document.getElementById(id)) return Promise.resolve();
  return new Promise((resolve, reject) => {
    const s$ = document.createElement('script');
    s$.onload = resolve;
    s$.src = src;
    s$.id = id;
    document.body.appendChild(s$);
    setTimeout(reject, 3000);
  });
}

async function loadFlockler(projectId, feedId) {
  // Loaded this way so there is no race-condition
  await loadScript('https://embed-cdn.flockler.com/embed-v2.js', 'flocker-embed');
  const url = `https://plugins.flockler.com/embed/${projectId}/${feedId}?autoload=false`;
  await loadScript(url, `flockler-script-${feedId}`);
}

const flocklerUtils = {
  /**
   * Initialize a flockler widget from projectId and feedId
   * Be careful it assumes that a DOM Node with an ID like flocklerUtils.domIfFromFeedID is present.
   * @param {string} projectId
   * @param {string} feedId
   * @returns {Promise<(function(): void)|*>} destroy callback
   */
  async initialize(projectId, feedId) {
    if (typeof window === 'undefined') return noop;
    await loadFlockler(projectId, feedId);

    const embed = window.flcklr.Embeds.create(window.flcklr.EmbedConfigs[feedId]);

    return () => {
      embed.destroy();
    };
  },
  domIdFromFeedId(feedId) {
    return `flockler-embed-${feedId}`;
  },
  getConfig() {
    return typeof window !== 'undefined' ? window.__DATA__.flockler : null;
  },
};

export default flocklerUtils;
