import { put, take, fork } from 'redux-saga/effects';

export function pipeChannel(channel) {
  return fork(function* _pipe() {
    try {
      while (true) {
        const event = yield take(channel);
        yield put(event);
      }
    } finally {
      if (channel) {
        channel.close();
      }
    }
  });
}
