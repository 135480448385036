/* eslint-disable max-len */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

const translationPrefix = 'blocks.countdown.date';

const CountdownDate = React.memo(({ date }) => {
  const { t } = useTranslation();
  return (
    <Header as="h2">
      <Header.Content>
        {t(`${translationPrefix}.header`, { date })}
        <Header.Subheader>{t(`${translationPrefix}.subheader`, { date })}</Header.Subheader>
      </Header.Content>
    </Header>
  );
});

export default CountdownDate;

CountdownDate.defaultProps = {};

CountdownDate.propTypes = {
  date: PropTypes.string.isRequired,
};
