/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Header, Segment } from 'semantic-ui-react';
import { getString } from '../../utils';
import VideoCard from './VideoCard';

const translationPrefix = 'blocks';

const VideosBlock = ({ videos, item, videoProps }) => {
  const visibleVideos = videos.filter((video) => video.file || video.value);
  if (visibleVideos.length === 0) return null;
  return (
    <Segment className="videos-block">
      <Header as="h3">{getString(`${translationPrefix}.videos`)}</Header>
      <br />

      <Card.Group centered>
        {visibleVideos.map((video, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <VideoCard key={index} video={video} item={item} videoProps={videoProps} />;
        })}
      </Card.Group>
    </Segment>
  );
};
VideosBlock.defaultProps = {
  videos: [],
  item: undefined,
  videoProps: undefined,
};
VideosBlock.propTypes = {
  item: PropTypes.object,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.sting,
    }),
  ),
  videoProps: PropTypes.object,
};

export default VideosBlock;
