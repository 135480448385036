/* eslint-disable operator-linebreak */
/* eslint-disable no-use-before-define */
/* eslint-disable no-case-declarations */

import jsep from 'jsep';
import every from 'lodash/every';
import some from 'lodash/some';
import { replaceValues } from './stringUtils';

const expressionCache = {};

jsep.addBinaryOp('&', 10);
jsep.addBinaryOp('OR', 1);
jsep.addBinaryOp('AND', 2);

function evalExpression(exp, dataFn) {
  switch (exp.type) {
    case 'UnaryExpression':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return evalUnary(exp, dataFn);
    case 'BinaryExpression':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return evalBin(exp, dataFn);
    case 'ConditionalExpression':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return evalCond(exp, dataFn);
    case 'CallExpression':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return evalCall(exp, dataFn);
    case 'MemberExpression':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return evalMember(exp, dataFn);
    case 'Identifier':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return evalIdentifier(dataFn, exp.name);
    case 'Literal':
      return exp.value;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown expression', exp);
      return null;
  }
}

function evalBin(exp, data) {
  const { operator, left, right } = exp;
  switch (operator) {
    case '+':
      return evalExpression(left, data) + evalExpression(right, data);
    case '-':
      return evalExpression(left, data) - evalExpression(right, data);
    case '*':
      return evalExpression(left, data) * evalExpression(right, data);
    case '/':
      return evalExpression(left, data) / evalExpression(right, data);
    case '<':
      return evalExpression(left, data) < evalExpression(right, data);
    case '<=':
      return evalExpression(left, data) <= evalExpression(right, data);
    case '==':
      // eslint-disable-next-line eqeqeq
      return evalExpression(left, data) == evalExpression(right, data);
    case '!=':
      // eslint-disable-next-line eqeqeq
      return evalExpression(left, data) != evalExpression(right, data);
    case '>':
      return evalExpression(left, data) > evalExpression(right, data);
    case '>=':
      return evalExpression(left, data) >= evalExpression(right, data);
    case '&':
      return String(evalExpression(left, data)) + String(evalExpression(right, data));
    case 'AND':
      return evalExpression(left, data) && evalExpression(right, data);
    case 'OR':
      return evalExpression(left, data) || evalExpression(right, data);
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown binary expression', exp);
      return null;
  }
}
function evalCond(exp, data) {
  const { test, consequent, alternate } = exp;
  return evalExpression(test, data)
    ? evalExpression(consequent, data)
    : evalExpression(alternate, data);
}

function evalCall(exp, data) {
  const { callee, arguments: args } = exp;
  switch (callee.name) {
    case 'UPPER':
      return String(evalExpression(args[0], data) || '').toUpperCase();
    case 'LOWER':
      return String(evalExpression(args[0], data) || '').toLowerCase();
    case 'TRIM':
      return String(evalExpression(args[0], data) || '').trim();
    case 'LEN': {
      const array = evalExpression(args[0], data);
      if (array === undefined || array === null) return 0;
      if ('length' in array) return array.length;
      console.warn("Object type doesn't have length", typeof array, array);
      return 0;
    }
    case 'SUBSTRING': {
      const [str, start, end] = args.map((arg) => evalExpression(arg, data));
      return String(str).slice(start, end);
    }
    case 'COUNT_BOOL': {
      const items = evalExpression(args[0], data);
      const field = String(evalExpression(args[1], data));
      return items?.filter((item) => item[field]).length;
    }
    case 'AND':
      return every(args, (arg) => evalExpression(arg, data));
    case 'OR':
      return some(args, (arg) => evalExpression(arg, data));

    default:
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const fn = evalIdentifier(data, callee.name);
      return fn(...args.map((arg) => evalExpression(arg, data)));
  }
}

function evalIdentifier(dataFn, key) {
  return dataFn(key);
}

function evalMember(exp, data) {
  const { object, property, computed } = exp;
  const obj = evalExpression(object, data);

  if (obj === undefined || obj === null) return undefined;

  if (computed) {
    const prop = evalExpression(property);
    return obj[prop];
  }
  return obj[property.name];
}

function evalUnary(exp, data) {
  const { operator, argument } = exp;
  switch (operator) {
    case '!':
      return !evalExpression(argument, data);
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown unary expression', exp);
      return null;
  }
}

/**
 * Returns a parsed expression
 * @param {*} expressionText
 */
export function parseExpression(expressionText) {
  return jsep(expressionText);
}

export function memoizedParsedExpression(expressionText) {
  // Quick cache
  if (expressionCache[expressionText]) return expressionCache[expressionText];

  const exp = parseExpression(expressionText);
  expressionCache[expressionText] = exp;
  return exp;
}

export function evalJsep(expressionText, data) {
  const exp = memoizedParsedExpression(expressionText);
  return evalExpression(exp, (key) => data[key]);
}

export function evalValue(v, context) {
  if (typeof v === 'string' || typeof v === 'number') {
    return v;
  }
  if (typeof v === 'object' && v.type === 'jsep') {
    return evalJsep(v.exp, context);
  }
  return undefined;
}

export function evalConstraint(constraint, context) {
  const { items = [] } = context;
  switch (constraint.type) {
    case 'maxCount':
      const maxCount = evalValue(constraint.maxCount, context);
      if (items.length >= maxCount)
        return { ...constraint, message: replaceValues(constraint.errorMessage, { maxCount }) };
      break;
    case 'condition':
      if (!evalValue(constraint.condition, context))
        return { ...constraint, message: replaceValues(constraint.errorMessage, context) };
      break;
    default:
      return null;
  }
  return null;
}
