/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSetState } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Comment, Dropdown, Form, Icon, Modal, TextArea } from 'semantic-ui-react';
import EntityLink from '../../../../components/EntityLink';
import UserBubble from '../../../../components/UserBubble';
import messagesService from '../../../../core/services/messages.service';
import store from '../../../../shared/Store';
import { showBlacklistError } from '../../../../social-wall/socialWallUtils';
import { fromNow } from '../../../../utils/dateUtils';
import { sweetAlert, sweetConfirmAlert } from '../../../../utils/popupUtils';

const translationPrefix = 'workshops.workshop.comments';

const EditCommentModal = ({ message, onEdit, onClose, maxChars }) => {
  const [newMessage, setMessage] = useState(message);
  const { t } = useTranslation();

  return (
    <Modal size="small" open closeIcon onClose={onClose}>
      <Modal.Header>
        <Icon name="pencil" />
        {t(`${translationPrefix}.edit-comment`)}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <TextArea
            value={message}
            name="message"
            onChange={(e) =>
              setMessage(maxChars ? (e.target.value || '').slice(0, maxChars) : e.target.value)
            }
            rows={5}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {maxChars && (
          <div className="comment--send--button">
            {message.length || 0} / {maxChars}
          </div>
        )}
        <Button
          disabled={newMessage === message}
          color="green"
          icon="save"
          content={t('btn.save')}
          onClick={onEdit}
        />
      </Modal.Actions>
    </Modal>
  );
};

EditCommentModal.defaultProps = {
  maxChars: undefined,
};

EditCommentModal.propTypes = {
  maxChars: PropTypes.number,
  message: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const WorkshopComment = React.memo(
  ({ comment, isAnonymous, workshopId, showUserOnThumbnailClick, maxChars }) => {
    const { id: messageId, createdAt, data } = comment;
    const { message, user, userId } = data;
    const isUserComment = userId === store.userId;
    const { t } = useTranslation();
    const [isOpen, setShowModal] = useState(false);

    const handleEdit = async (newMessage) => {
      const res = await messagesService.patchMessage('comments', workshopId, messageId, {
        message: newMessage,
      });
      if (res.errors) {
        showBlacklistError(res, newMessage);
      } else {
        setShowModal(false);
      }
    };
    const handleDelete = async () => {
      const confirm = await sweetConfirmAlert({
        title: t(`${translationPrefix}.delete-comment`),
        confirmButtonText: t(`alert.confirm`),
        cancelButtonText: t(`alert.cancel`),
      });
      if (confirm) {
        const res = await messagesService.deleteMessage('comments', workshopId, messageId);
        if (res.errors) {
          sweetAlert({ title: t('errors.error'), icon: 'error' });
        }
      }
    };
    return (
      <Comment>
        <div style={{ display: 'flex' }}>
          <UserBubble
            as={showUserOnThumbnailClick ? EntityLink : undefined}
            entity={{ kind: 'users', collection: 'participants', _id: userId, ...user }}
            user={{ ...user, _id: userId }}
            isAnonymous={isAnonymous}
          />
          <Comment.Content style={{ marginLeft: 8, flex: 1 }}>
            {!isAnonymous && (
              <Comment.Author>{`${user.firstName || ''} ${user.lastName || ''}`}</Comment.Author>
            )}
            <Comment.Metadata>
              <div>{fromNow(createdAt)}</div>
            </Comment.Metadata>
            <Comment.Text>{message}</Comment.Text>
          </Comment.Content>
          {isUserComment && (
            <Dropdown icon="ellipsis vertical">
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="pencil"
                  text={t('btn.edit')}
                  onClick={() => setShowModal(true)}
                />
                <Dropdown.Item icon="trash" text={t('btn.delete')} onClick={handleDelete} />
              </Dropdown.Menu>
            </Dropdown>
          )}
          {isOpen && (
            <EditCommentModal
              message={message}
              onEdit={handleEdit}
              onClose={() => setShowModal(false)}
              maxChars={maxChars}
            />
          )}
        </div>
      </Comment>
    );
  },
);

WorkshopComment.defaultProps = {
  isAnonymous: false,
  maxChars: undefined,
  showUserOnThumbnailClick: false,
};

WorkshopComment.propTypes = {
  comment: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.string,
    data: PropTypes.shape({
      message: PropTypes.string,
      user: PropTypes.object,
      userId: PropTypes.string,
    }),
  }).isRequired,
  isAnonymous: PropTypes.bool,
  maxChars: PropTypes.number,
  showUserOnThumbnailClick: PropTypes.bool,
  workshopId: PropTypes.string.isRequired,
};

export default WorkshopComment;
