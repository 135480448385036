/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'semantic-ui-react';
import get from 'lodash/get';
import { containerTypePropTypes, workshopArrayProptypes, workshopProptypes } from '../../propTypes';
import BlockContainer from '../../components/BlockContainer';
import { ellipsisIfNeeded } from '../../home/blocks/SocialWallWidgetBlock';
import EntityLink from '../../components/EntityLink';
import Images from '../../utils/Images';
import CarouselBlock from '../../components/cms/CarouselBlock';
import VodCard from '../../components/workshops/variants/VodCard';
import { useCollectionAndCategoryWorkshops } from '../store/workshops.hooks';

export const SuggestedWorkshopsList = ({ workshops }) => (
  <Item.Group unstackable>
    {workshops.map((w) => {
      const { title: header, _id, image, shortDescription } = w;
      return (
        <Item key={_id} as={EntityLink} entity={{ ...w, kind: 'workshop' }}>
          {image && image.uri && (
            <Item.Image size="small" src={Images.cdn(image)} style={{ maxWidth: 100 }} />
          )}
          <Item.Content style={{ paddingLeft: 10 }}>
            <Item.Header style={{ fontSize: '1em' }}>{header}</Item.Header>
            <Item.Meta style={{ fontSize: 12 }}>{ellipsisIfNeeded(shortDescription, 70)}</Item.Meta>
          </Item.Content>
        </Item>
      );
    })}
  </Item.Group>
);

SuggestedWorkshopsList.propTypes = {
  workshops: workshopArrayProptypes.isRequired,
};

export const SuggestedWorkshopsCarousel = ({ workshops, carousel, itemProps, container }) => (
  <CarouselBlock
    items={workshops}
    carousel={carousel}
    container={container}
    component={(workshop) => <VodCard {...itemProps} workshop={workshop} />}
  />
);

SuggestedWorkshopsCarousel.defaultProps = {
  carousel: {},
  container: {},
  itemProps: {},
};

SuggestedWorkshopsCarousel.propTypes = {
  carousel: PropTypes.object,
  container: PropTypes.object,
  itemProps: PropTypes.object,
  workshops: workshopArrayProptypes.isRequired,
};

const components = {
  list: SuggestedWorkshopsList,
  carousel: SuggestedWorkshopsCarousel,
};

function CollectionAndCategoryWorkshops(props) {
  const { item: workshop, title, variant, container, containerType, maxItems, ...rest } = props;
  const { collection, category } = workshop;
  const { carousel, itemProps } = rest;
  const suggestedWorkshops = useCollectionAndCategoryWorkshops(collection, category).filter(
    ({ _id }) => _id !== workshop._id,
  );
  const finalWorkshops = suggestedWorkshops.slice(0, maxItems);
  if (finalWorkshops.length === 0) return null;
  const Component = get(components, variant, SuggestedWorkshopsList);
  return (
    <BlockContainer
      className="workshop--collectionAndCategoryWorkshops"
      header={title}
      type={containerType}
      {...container}
    >
      <Component
        workshops={finalWorkshops}
        carousel={carousel}
        container={container}
        itemProps={itemProps}
      />
    </BlockContainer>
  );
}

CollectionAndCategoryWorkshops.defaultProps = {
  container: {},
  containerType: 'segment',
  maxItems: undefined,
  title: '',
  variant: 'list',
};

CollectionAndCategoryWorkshops.propTypes = {
  container: PropTypes.object,
  containerType: containerTypePropTypes,
  item: PropTypes.shape(workshopProptypes).isRequired,
  maxItems: PropTypes.number,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default CollectionAndCategoryWorkshops;
