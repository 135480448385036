import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import store from '../../shared/Store';
import { useFirebase, useFirestoreCollection } from '../../utils/useFirebase';

export function usePlatformMessages(kind, collection, groupId) {
  const firebase = useFirebase();
  const messagesRef = useMemo(
    () =>
      firebase
        //   ?.firestore()
        //   .collection('messages')
        //   .doc(store.eventId)
        //   .collection(collection)
        //   .doc(groupId)
        //   .collection('messages'),
        ?.firestore()
        .collection('messages')
        .doc(store.eventId)
        .collection('kind')
        .doc(kind)
        .collection('collections')
        .doc(collection)
        .collection('topics')
        .doc(groupId)
        .collection('items'),
    [firebase, kind, collection, groupId],
  );
  const { list: messages } = useFirestoreCollection(messagesRef);
  const memoizedMessages = useMemo(() => orderBy(messages, 'createdAt', 'desc'), [messages]);
  return memoizedMessages;
}
