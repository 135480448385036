import keyBy from 'lodash/keyBy';

const appointmentReducer = (state = { appointments: [], appointmentsById: {} }, action) => {
  switch (action.type) {
    case 'SET_APPOINTMENTS':
      return {
        appointments: action.appointments,
        appointmentsById: keyBy(action.appointments, '_id'),
      };
    case 'GET_ALL_APPOINTMENTS':
      return state;
    default:
      return state;
  }
};

export function setAppointments(appointments) {
  return {
    type: 'SET_APPOINTMENTS',
    appointments,
  };
}

export default appointmentReducer;
