import { useSetState } from 'ahooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Form } from 'semantic-ui-react';

import FooterModal from '../../components/Footer/FooterModal';
import { useConfig } from '../../config/config.context';
import useTranslations from '../../hooks/useTranslations';
import store, { getParameterByName } from '../../shared/Store';
import { getString } from '../../utils';
import { markdownUrlify } from '../../utils/stringUtils';
import { useLoginConfig } from '../hooks';
import LegalNoticeModal from './LegalNoticeModal';
import './LoginPasswordForm.scss';
import Messages from './Messages';
import { PasswordInput } from './PasswordInput';
import SsoLogin from './SsoLogin';

function useLoginErrorString() {
  const { t } = useTranslation();

  return (code) => t(`login.errors.${code}`, { defaultValue: t(`login.errors.E_AUTH_ERROR`) });
}

const LoginPasswordForm = ({ onLogin }) => {
  const { t } = useTranslations('login');
  const errorMsg = useLoginErrorString();
  const loginConfig = useLoginConfig();
  const { footer } = useConfig();
  const { blocks = [] } = footer || {};
  const modal = blocks.find(({ type }) => type === 'modal');

  const errorCode = getParameterByName('code');
  const [state, setState] = useSetState(() => ({
    login: getParameterByName('email'),
    stayConnected: loginConfig.stayConnected ?? true,
    displayLegalNotice: false,
    error: errorCode ? errorMsg(errorCode) : undefined,
    message: undefined,
    showFooterModal: false,
    passwordVisible: false,
  }));
  const { legalNoticeModal, legalNoticeCheckbox = false, sso } = loginConfig;

  const {
    displayLegalNotice,
    login,
    password,
    error,
    message,
    stayConnected,
    showFooterModal,
  } = state;

  const disabled = !login || !password || (legalNoticeCheckbox && !displayLegalNotice);

  function handleChange(_e, { name, value, checked }) {
    setState({ [name]: value || checked, message: null, error: null });
  }

  async function handleLogin() {
    try {
      const res = await store.login(login, password, stayConnected, {
        validatedLegalNotice: displayLegalNotice,
      });
      if (store.isLoggedIn()) {
        onLogin();
      } else {
        const { code } = res.errors?.[0] || {};

        setState({
          displayLegalNotice: false,
          error: errorMsg(code),
        });
      }
    } catch (e) {
      setState({
        error: t('error-connection-error'),
        displayLegalNotice: false,
      });
    }
  }

  async function handleLoginClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (legalNoticeModal && !displayLegalNotice) {
      setState({ displayLegalNotice: true });
      return;
    }
    handleLogin();
  }

  function handleLinkClick(e) {
    e.preventDefault();
    e.stopPropagation();
    setState({ showFooterModal: true });
  }

  return (
    <>
      {sso && <SsoLogin sso={sso} />}
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder={t('login')}
        name="login"
        value={login || ''}
        onChange={handleChange}
      />
      <PasswordInput name="password" onChange={handleChange} password={password} />
      {legalNoticeCheckbox && (
        <Form.Field className="login-form__options login-form__options--rgpd">
          <Checkbox
            name="displayLegalNotice"
            className="form-login--accept-legal-notice-checkbox"
            checked={displayLegalNotice}
            onChange={handleChange}
          />
          <p className="form-login--accept-legal-notice-label">
            {markdownUrlify(t('accept-legal-notice'), { onLinkClick: handleLinkClick })}
          </p>
        </Form.Field>
      )}
      <Form.Field className="login-form__options login-form__options--stay-connected">
        <Checkbox
          name="stayConnected"
          className="form-login--stay-connected-checkbox"
          checked={stayConnected}
          label={t('accept-save-data')}
          onChange={handleChange}
        />
        <Link
          className="link--forgotten-password"
          to="/forgottenPassword"
          style={{ float: 'right' }}
        >
          {t('forgotten-password')}
        </Link>
      </Form.Field>
      <Button primary fluid size="large" disabled={disabled} onClick={handleLoginClick}>
        {getString('btn.connect')}
      </Button>
      <Messages message={message} error={error} />
      {displayLegalNotice && legalNoticeModal && (
        <LegalNoticeModal open {...legalNoticeModal} onClick={handleLogin} />
      )}
      {showFooterModal && modal && (
        <FooterModal {...modal} isOpen onClose={() => setState({ showFooterModal: false })} />
      )}
    </>
  );
};

LoginPasswordForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginPasswordForm;
