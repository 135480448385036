import some from 'lodash/some';
import { SET } from './workshopSessions.types';

const initialState = {
  workshopSessions: [],
};

function buildStore(sessions) {
  let workshopSessions = sessions;
  if (some(sessions, (w) => w.replayCollection)) {
    workshopSessions = sessions.map((w) => {
      if (w.replayCollection && w.replays?.length) {
        return { ...w, reference: `vod/${w.slug}` }; // Ugly OVH hack
      }
      return w;
    });
  }
  return { workshopSessions };
}

export default function reduceWorkshopSession(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return buildStore(action.workshopSessions);
    }
    default:
      return state;
  }
}
