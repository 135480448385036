/* eslint-disable no-console */

/* eslint-disable no-alert */
import { FC, useState } from 'react';
import { Button } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import { refreshNotificationRegistrations } from '../../core/firebase/firebase-messaging';
import useTranslations from '../../hooks/useTranslations';
import { useVisibilityChange } from '../../hooks/useVisibilityChange';
import { hasWebPush, isPWA } from '../../utils/pwaUtils';
import BlockContainer from '../BlockContainer';
import './NotificationRequestButton.scss';

const className = bem('notificationRequestButton');

type NotificationRequestButtonProps = {
  title?: string;
  displayMode?: 'show-if-not-granted' | 'show-only-if-default';
};

function withFeatureAvailable<T>(Component: FC<T>, isAvailable: () => boolean): FC<T> {
  if (!isAvailable()) return () => null;
  return Component;
}

function canHaveRequestButton() {
  const enabled = isPWA() && hasWebPush();
  console.log('canHaveRequestButton', enabled, { pwa: isPWA(), webPush: hasWebPush() });
  return enabled;
}

const NotificationRequestButton: FC<NotificationRequestButtonProps> = (props) => {
  const [state, setState] = useState(() => Notification.permission);
  const { t } = useTranslations();
  const { title, displayMode = 'show-if-not-granted' } = props;

  useVisibilityChange((isVisible) => {
    // Check again, just in case...
    if (isVisible) {
      if (state !== 'granted' && Notification.permission === 'granted') {
        console.log('On visibility change, notification state changed to granted !');
        // Changed to granted, refresh !!
        refreshNotificationRegistrations();
      }
      setState(Notification.permission);
    }
  });

  const handleInitialPrompt = () => {
    Notification.requestPermission().then((result) => {
      console.log('requestPermission =>', result);
      if (result === 'granted') {
        refreshNotificationRegistrations();
      }
      setState(result);
    });
  };

  const handlePromptAgain = () => {
    console.warn("Can't ask again, already denied :/ ");
    window.alert(t('notifications.permission-denied-message'));
  };

  switch (state) {
    case 'default': {
      // default : ask
      return (
        <BlockContainer className={className({ state })} type="basic">
          <Button primary onClick={handleInitialPrompt}>
            {title || t('notifications.request-button')}
          </Button>
        </BlockContainer>
      );
    }
    case 'denied': {
      if (displayMode === 'show-only-if-default') return null;
      // denied : can't ask anymore...
      return (
        <BlockContainer className={className({ state })} type="basic">
          <Button primary onClick={handlePromptAgain}>
            {title || t('notifications.request-button')}
          </Button>
        </BlockContainer>
      );
    }
    case 'granted': {
      // All good, hide
      return null;
    }
    default: {
      console.warn('Unknown notif state', state);
      return null;
    }
  }
};

export default withFeatureAvailable(NotificationRequestButton, canHaveRequestButton);
