import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'ahooks';
import { get, groupBy, keyBy, maxBy, orderBy } from 'lodash';
import { useUserCollection } from '../../core/users/users.hooks';
import { fetchMatching } from './matching.actions';
import store from '../../shared/Store';
import { fetchUsersByCollection } from '../../core/users/users.actions';

export function useMatchings(scoreCriteria = 'positive') {
  const opportunities = useSelector((state) => state.matching.matching?.opportunities);

  // TODO: throttle / cache ?
  useMount(() => store.reduxStore.dispatch(fetchMatching(scoreCriteria)));
  return opportunities || [];
}

export function useMatchingsWithExhibitors(
  userCollection = 'exhibitors',
  scoreCriteria = 'positive',
) {
  const users = useUserCollection(userCollection);
  const opportunities = useMatchings(scoreCriteria);

  useMount(() => store.reduxStore.dispatch(fetchUsersByCollection(userCollection)));
  const appointments = useSelector((state) => state.appointments.appointments);
  const appointmentsByOfferId = keyBy(appointments, 'opportunityId');

  return useMemo(() => {
    if (!users) return opportunities || [];

    const usersById = keyBy(users, '_id');
    return opportunities.map((o) => ({
      ...o,
      parent: usersById[o.ownerId],
      appointment: get(appointmentsByOfferId, o._id),
    }));
  }, [users, opportunities, appointmentsByOfferId]);
}

export function useExhibitorsWithMatching(
  userCollection = 'exhibitors',
  scoreCriteria = 'positive',
) {
  const users = useUserCollection(userCollection);
  const opportunities = useMatchings(scoreCriteria);

  useMount(() => store.reduxStore.dispatch(fetchUsersByCollection(userCollection)));

  return useMemo(() => {
    if (!opportunities) return users || [];
    const opportunitiesByUser = groupBy(opportunities, 'ownerId');
    return orderBy(
      users.map((u) => {
        const userOpportunities = get(opportunitiesByUser, u._id, []);
        return {
          ...u,
          opportunities: userOpportunities,
          maxScore: get(maxBy(userOpportunities, 'score'), 'score'),
        };
      }),
      'maxScore',
      'desc',
    );
  }, [users, opportunities]);
}

export function useExhibitorOpportunities(userCollection, userId, scoreCriteria = 'positive') {
  const matchings = useMatchingsWithExhibitors(userCollection, scoreCriteria);
  return useMemo(() => {
    if (!matchings) return [];
    return matchings.filter((m) => m.ownerId === userId);
  }, [matchings, userId]);
}
