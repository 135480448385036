import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { useIsAnonymous } from '../../profile/hooks';
import { userProptypes } from '../../propTypes';
import { getString } from '../../utils';
import CdnImage from '../CdnImage';

const translationPrefix = 'menu';

export const Avatar = ({ user, onClick }) => {
  const { firstName, lastName, thumbnail } = user;
  if (thumbnail) {
    return <CdnImage src={thumbnail} maxHeight={40} circular onClick={onClick} />;
  }
  return (
    <Button className="button--avatar" circular basic style={{ padding: '9px' }} onClick={onClick}>
      {(firstName || '').substring(0, 1)}
      {(lastName || '').substring(0, 1)}
    </Button>
  );
};

const ConnectedThumbnailButton = ({ user, onClick }) => {
  const dropdownTrigger = <Avatar user={user} />;

  return (
    <Menu.Item
      className="menu__logout"
      style={{
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        position: 'unset',
      }}
    >
      <Dropdown trigger={dropdownTrigger} pointing="top right" icon={null}>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onClick}>
            {getString(`${translationPrefix}.logout`)}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};
ConnectedThumbnailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  user: PropTypes.shape(userProptypes).isRequired,
};
const ThumbnailButton = connect((state) => ({
  user: state.user.user,
}))(ConnectedThumbnailButton);

const CommonButton = ({ onClick }) => (
  <Menu.Item className="menu__logout" onClick={onClick}>
    <Icon name="log out" />
    <span className="large monitor only">{getString(`${translationPrefix}.logout`)}</span>
  </Menu.Item>
);
CommonButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const LogoutButton = (props) => {
  const { menuConfig, onClick } = props;
  const { thumbnailButton } = menuConfig;
  const isAnonymous = useIsAnonymous();
  if (isAnonymous) return null; // No logout button required...

  const ButtonComponent = thumbnailButton ? ThumbnailButton : CommonButton;

  return <ButtonComponent onClick={onClick} />;
};
LogoutButton.defaultProps = {
  menuConfig: {},
};

LogoutButton.propTypes = {
  menuConfig: PropTypes.shape({ thumbnailButton: PropTypes.bool }),
  onClick: PropTypes.func.isRequired,
};

export default LogoutButton;
