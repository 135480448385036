/* eslint-disable class-methods-use-this */
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import store from '../../shared/Store';
import platformService from './platform.service';

const { endpoints } = window.__DATA__;

class MessagesService {
  postMessage(topicKind, messageData, { parent }) {
    if (!isString(topicKind)) throw new TypeError(`Invalid message 'topicKind'`);
    if (!isObject(parent)) throw new TypeError(`Invalid message 'parent'`);

    return platformService.securePost(`${endpoints.platform}/messages/${topicKind}`, {
      ...messageData,
      parent,
      userId: store.userId,
    });
  }

  patchMessage(collection, groupId, messageId, patch) {
    return platformService.securePatch(
      `${endpoints.platform}/messages/${collection}/${groupId}/messages/${messageId}`,
      { ...patch, userId: store.userId },
    );
  }

  fetchMessages(collection, groupId) {
    return platformService.secureGet(
      `${endpoints.platform}/messages/${collection}/${groupId}/messages`,
    );
  }

  deleteMessage(collection, groupId, messageId) {
    return platformService.secureDelete(
      `${endpoints.platform}/messages/${collection}/${groupId}/messages/${messageId}`,
    );
  }
}

export default new MessagesService();
