import { SET } from './matching.types';

const initialState = {
  matching: { users: [], offers: [] },
};

export default function reduceMatching(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { matching: action.matching };
    }
    default:
      return state;
  }
}
