import React from 'react';
import CarouselBlock from '../../../components/cms/CarouselBlock';
import { bem } from '../../../core/design/bem';
import VodCard from '../../../components/workshops/variants/VodCard';
import { Item } from '../../../components/cms/CarouselBlock/types';

const css = bem('AgendaCarousel');

type WorkshopConfigProps = {
  collection?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

type AgendaCarouselProps = {
  _id: string;
  events?: Item[];
  workshopConfig?: WorkshopConfigProps;
} & typeof AgendaCarouselDefaultProps;

const AgendaCarouselDefaultProps = {
  events: [],
  workshopConfig: {},
};

const AgendaCarousel = ({ events, workshopConfig, ...rest }: AgendaCarouselProps): JSX.Element => {
  const { collection } = workshopConfig;
  const filteredEvents = collection ? events.filter((e) => e.collection === collection) : events;
  return (
    <div className={css()} style={{ width: '100%' }}>
      <CarouselBlock
        {...rest}
        className={css()}
        items={filteredEvents}
        component={(item: Item) => <VodCard workshop={item} {...workshopConfig} />}
      />
    </div>
  );
};

AgendaCarousel.defaultProps = AgendaCarouselDefaultProps;

export default AgendaCarousel;
