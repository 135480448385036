import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import keyBy from 'lodash/keyBy';
import { connect } from 'react-redux';
import ItemCardGroup from '../ItemCardGroup';
import ReplayCard from './ReplayCard';
import VideoModal from '../video/VideoModal';
import { workshopArrayProptypes } from '../../propTypes';
import './ReplayBlock.scss';
import { mapIds } from './utils';

const ReplayBlock = (props) => {
  const { groupBy: groupByProps, title, workshopIdList, workshops } = props;
  const [replay, setReplay] = useState();
  const items = useMemo(() => {
    if (!workshopIdList || !workshops) return workshops;

    return mapIds(workshopIdList, keyBy(workshops, '_id'));
  }, [workshopIdList, workshops]);

  if (!items?.length) return null;

  return (
    <Segment className="block--replay">
      {title && <Header as="h2">{title}</Header>}
      <ItemCardGroup
        centered
        groupClassName="replays"
        items={items}
        groupBy={groupByProps}
        component={ReplayCard}
        onClick={setReplay}
      />
      {replay && <VideoModal video={replay} onClose={() => setReplay(null)} />}
    </Segment>
  );
};

ReplayBlock.defaultProps = {
  groupBy: {},
  title: undefined,
  workshopIdList: undefined,
  workshops: [],
};
ReplayBlock.propTypes = {
  groupBy: PropTypes.object,
  title: PropTypes.string,
  workshopIdList: PropTypes.arrayOf(PropTypes.string),
  workshops: workshopArrayProptypes,
};

export default connect((state) => ({
  workshops: state.workshops.workshops.filter((w) => w.replays?.length > 0),
}))(ReplayBlock);
