/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import { Button, Card, Header, Pagination } from 'semantic-ui-react';

import BlockContainer from '../../../components/BlockContainer';
import { ContainerProps } from '../../../components/cms/types';
import SquareImage from '../../../components/SquareImage';
import { bem } from '../../../core/design/bem';
import useContactSearch from '../../../networking/hooks/useContactSearch';
import { replaceValues } from '../../../utils/stringUtils';
import './UserProfileNetworkingBlock.scss';
import EntityLink from '../../../components/EntityLink';
import { usePagination } from '../../../utils/hooks';

const translationPrefix = 'profile.networking';

const css = bem('UserProfileNetworkingBlock');

type UserNetworkingBlockProps = {
  container?: ContainerProps;
  variant?: 'simple';
  config?: Record<string, any>;
};

const SimpleCard = (props: any): JSX.Element => {
  const { t } = useTranslation();
  const { user, config } = props;
  const { defaultThumbnail, roleTemplate = '{role}', companyTemplate = '{company}' } = config;
  const { firstName, lastName, thumbnail = defaultThumbnail } = user;
  const role = replaceValues(roleTemplate, user);
  const company = replaceValues(companyTemplate, user);
  return (
    <Card className={css('card', { variant: 'simple' }).toString()}>
      <SquareImage src={thumbnail} imageSize={60} className="thumbnail" cdnOptions={{}} circular />
      <Card.Content style={{ border: 'none' }}>
        <Header as="h4" className="fullname">
          {firstName} {lastName}
        </Header>
        {role && <Card.Description className="role">{role}</Card.Description>}
        {company && <Card.Description>{company}</Card.Description>}
        <Button
          primary
          size="small"
          className="show-more"
          as={EntityLink}
          entity={{ kind: 'users', collection: 'participants', ...user }}
        >
          {t(`${translationPrefix}.see-profile`)}
        </Button>
      </Card.Content>
    </Card>
  );
};

SimpleCard.defaultProps = {
  config: {},
};

const components = {
  simple: SimpleCard,
};

const UserProfileNetworkingBlock = ({
  variant = 'simple',
  container,
  config = {},
}: UserNetworkingBlockProps): JSX.Element => {
  const { contacts = [] } = useContactSearch();
  const { itemsPerLine = 4, pagination } = config;
  const Component = get(components, variant, SimpleCard);
  const { maxItems, showPreviousAndNextNav } = pagination || {};
  const { pageItems, setActivePage = noop, totalPages = 0 } = usePagination(contacts, maxItems);
  return (
    <div className={css()}>
      <BlockContainer {...container}>
        <Card.Group stackable itemsPerRow={itemsPerLine}>
          {pageItems.map((contact: any) => {
            const { _id: userId } = contact;
            return <Component key={userId} user={contact} config={config?.itemConfig} />;
          })}
        </Card.Group>
        {maxItems && totalPages > 1 && (
          <div style={{ textAlign: 'center', marginTop: 15 }}>
            <Pagination
              className="paginator"
              boundaryRange={0}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={showPreviousAndNextNav ? undefined : null}
              nextItem={showPreviousAndNextNav ? undefined : null}
              siblingRange={2}
              defaultActivePage={1}
              totalPages={totalPages}
              pointing
              secondary
              {...pagination}
              onPageChange={(_e, data) => {
                const { activePage: i } = data;
                setActivePage(i);
              }}
            />
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

UserProfileNetworkingBlock.defaultProps = {
  config: {},
  container: {},
  variant: 'simple',
};

export default UserProfileNetworkingBlock;
