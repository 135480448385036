// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { SET } from './matching.types';

export function setMatching(matching) {
  return {
    type: SET,
    matching,
  };
}

export function fetchMatching(scope) {
  return async (dispatch) => {
    const matching = await platformService.fetchMatching(scope);
    if (matching) {
      dispatch(setMatching(matching));
    }
  };
}
