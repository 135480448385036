import React from 'react';
import PropTypes from 'prop-types';
import { userArrayProptypes } from '../../../propTypes';
import { replaceValues } from '../../../utils/stringUtils';
import EntityLink from '../../EntityLink';

const TextVariant = ({ template, users, ...rest }) => {
  if (users.length === 0) return null;
  const { showModal = false } = rest || {};
  const usersName = users.map((user) => ({ label: replaceValues(template, user), ...user }));

  if (showModal) {
    return (
      <div>
        {usersName.map((user, index) => {
          const { _id, label } = user;
          const comma = index !== users.length - 1 ? ', ' : '';
          return (
            <EntityLink
              key={_id}
              entity={{ ...user, kind: 'users' }}
            >{`${label}${comma}`}</EntityLink>
          );
        })}
      </div>
    );
  }
  return <div>{usersName.map((u) => u?.label).join(', ')}</div>;
};

TextVariant.defaultProps = {
  template: '{firstName} {lastName}',
  users: [],
};
TextVariant.propTypes = {
  template: PropTypes.string,
  users: userArrayProptypes,
};

export default TextVariant;
