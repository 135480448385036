import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';

// eslint-disable-next-line import/no-cycle
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import useTranslations from '../../hooks/useTranslations';
import { dateFNSLocale, forceTimezone, localeFormat, momentTz } from '../../utils/date';
import './WorkshopDate.scss';

function formatDate(date, dateConfig) {
  const isoDate = parseISO(date);
  if (forceTimezone()) {
    const { dateFormat = 'LL', timeFormat = 'LT z' } = dateConfig;
    return {
      date: momentTz(isoDate).format(dateFormat),
      time: momentTz(isoDate).format(timeFormat),
    };
  }
  const dateOptions = { locale: dateFNSLocale() };
  const { dateFormat = 'PP', timeFormat = 'p' } = dateConfig;
  return {
    date: localeFormat(isoDate, dateFormat, dateOptions),
    time: localeFormat(isoDate, timeFormat, dateOptions),
  };
}

function isSameDay(date1, date2) {
  return !!(date1 && date2 && moment(parseISO(date1)).isSame(parseISO(date2), 'date'));
}

function computeDuration(startDate, endDate) {
  if (!startDate || !endDate) return undefined;
  return parseISO(endDate).getTime() - parseISO(startDate).getTime();
}

export const WorkshopTimeRemaining = ({ date }) => {
  const now = useAutoRefresh(10000);
  const d = moment(date);
  if (d.isAfter(now - 5 * 60 * 1000)) {
    return <span>{d.fromNow()}</span>;
  }
  return null;
};

WorkshopTimeRemaining.propTypes = {
  date: PropTypes.string.isRequired,
};

const WorkshopDate = ({
  className,
  startDate,
  endDate,
  timezone,
  showEndTime,
  showDuration,
  showTimezone,
  showDay,
  dateConfig,
  hideIcon,
}) => {
  const { t } = useTranslations('workshops.date');
  if (!startDate) return null;
  const { date, time: startTime } = formatDate(startDate, dateConfig);
  const { date: endDay, time: endTime } = (endDate && formatDate(endDate, dateConfig)) || {};

  if (timezone) {
    const formattedStartDate = moment(parseISO(startDate)).tz(timezone);
    const formattedEndDate = moment(parseISO(endDate)).tz(timezone);
    return (
      <span className="datetime">
        {t('time', { timezone, startDate: formattedStartDate, endDate: formattedEndDate })}
      </span>
    );
  }

  const timeZone = format(parseISO(startDate), ' O', undefined);
  const duration = Math.round((computeDuration(startDate, endDate) || 0) / (60 * 1000));
  const endsSameDay = isSameDay(startDate, endDate);

  return (
    <span className={cx('datetime', className)}>
      {showDay && (
        <span className="date" style={{ marginRight: 8 }}>
          <Icon name="calendar alternate outline" />
          &nbsp;{date}
        </span>
      )}
      <span className="time" style={{ marginRight: 8 }}>
        <Icon name="clock" />
        {t('start-date', { startDate: startTime, start: startDate })}{' '}
        {endDate &&
          showEndTime &&
          endsSameDay &&
          t('to-end-date', { endDate: endTime, end: endDate })}
        {showTimezone && <span className="timezone"> - {timeZone}</span>}
      </span>
      {endDate && showEndTime && !endsSameDay && (
        <div className="time-end-details">
          <span className="date" style={{ marginRight: 8 }}>
            {t('to-end-date-separate', { endDay, endTime })}
          </span>
        </div>
      )}
      {showDuration && duration && (
        <span className="duration">
          <Icon name="hourglass half" />
          &nbsp;{duration}&nbsp;minutes
        </span>
      )}
    </span>
  );
};

WorkshopDate.defaultProps = {
  className: undefined,
  dateConfig: {},
  hideIcon: false,
  startDate: undefined,
  endDate: undefined,
  showDay: true,
  showDuration: false,
  showEndTime: false,
  showTimezone: true,
  timezone: undefined,
};

WorkshopDate.propTypes = {
  className: PropTypes.string,
  dateConfig: PropTypes.object,
  endDate: PropTypes.string,
  hideIcon: PropTypes.bool,
  startDate: PropTypes.string,
  showDay: PropTypes.bool,
  showDuration: PropTypes.bool,
  showEndTime: PropTypes.bool,
  showTimezone: PropTypes.bool,
  timezone: PropTypes.string,
};

export default WorkshopDate;
