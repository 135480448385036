import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ExpiringNavLink from '../ExpiringNavLink';

const FooterLink = ({ className, text, isNavLink, ...props }) => {
  const Component = isNavLink ? ExpiringNavLink : 'a';
  return (
    <Component
      className={cx('footer__link', className)}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
    >
      {text}
    </Component>
  );
};

FooterLink.defaultProps = {
  className: undefined,
  isNavLink: false,
};

FooterLink.propTypes = {
  className: PropTypes.string,
  isNavLink: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default FooterLink;
