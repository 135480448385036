import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { useUpdateConfig } from '../config/config.context';
import { PreviewMode } from '../config/preview.context';
import store from '../shared/Store';
import Styles from '../Styles';
import { usePageFromEntity } from '../utils/hooks';
import { Exhibitor } from './pages/ExhibitorPage';

const ExhibitorPreview = () => {
  const [exhibitor, setExhibitor] = useState(null);
  const updateConfig = useUpdateConfig();
  const page = usePageFromEntity(exhibitor);

  store.isPreview = true;
  useEffect(() => {
    function onMessage(event) {
      const { sponsor: legacySponsorData, exhibitor: exhibitorData, lang } = event.data;
      if (exhibitorData || legacySponsorData) {
        updateConfig({
          lang,
        });
        setExhibitor(exhibitorData || legacySponsorData);
      }
    }

    if (process.env.NODE_ENV === 'production') {
      window.addEventListener('message', onMessage, false);
      return () => {
        window.removeEventListener('message', onMessage, false);
      };
    }
    // Dev mode, launch now
    const { preview, lang } = window.__DATA__;
    onMessage({
      data: { exhibitor: preview.exhibitor, lang },
    });
    return undefined;
  }, [updateConfig]);

  if (!exhibitor && !page) return null;

  return (
    <PreviewMode>
      <Provider store={store.reduxStore}>
        <Styles />
        <Exhibitor user={exhibitor} {...page} />
      </Provider>
    </PreviewMode>
  );
};

export default ExhibitorPreview;
