import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Footer from '../../../../components/Footer';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const defaultProps = {
  className: undefined as Props['className'],
};

const LoginColumnsContainer = ({
  className,
  children,
}: Props & typeof defaultProps): JSX.Element => {
  return (
    <div className={cx('columns-container', className)}>
      <div className="columns-center-wrapper">
        <Grid textAlign="center" stackable relaxed verticalAlign="middle">
          <Grid.Column className="column--auth-content" width={4}>
            {children}
          </Grid.Column>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

LoginColumnsContainer.defaultProps = defaultProps;

LoginColumnsContainer.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default LoginColumnsContainer;
