// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { SET_ALL, SET_BY_ID } from './participants.types';

export const setParticipants = (participants) => ({
  type: SET_ALL,
  participants,
});

export const setParticipant = (sponsor) => ({
  type: SET_BY_ID,
  sponsor,
});

export function fetchParticipants() {
  return async (dispatch) => {
    const participants = await platformService.fetchParticipants();
    dispatch(setParticipants(participants));
  };
}
