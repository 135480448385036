import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Card, Icon, Button } from 'semantic-ui-react';
import { getString } from '../../../utils';
import { appointmentProptypes } from '../../../propTypes';
import store from '../../../shared/Store';
import { dateFNSLocale } from '../../../utils/date';

const { endpoints } = window.__DATA__;

function useAutoRefresh(milliseconds) {
  const [, setNow] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, milliseconds);
    return () => clearInterval(interval);
  }, [milliseconds]);
}

const AccessMeetingRoomBlock = ({ appointment }) => {
  useAutoRefresh(60 * 1000); // refresh evry 60s
  const { collection, _id, startDate } = appointment;
  return (
    <Card className="BookAppointmentBlock--meeting">
      <Card.Content>
        <Card.Header as="h3" className="title colored">
          <Icon name="clock outline" />
          {formatDistanceToNow(parseISO(startDate), { addSuffix: true, locale: dateFNSLocale() })}
        </Card.Header>
      </Card.Content>
      <Card.Content className="room-tester">
        <Card.Description>{getString('appointments.test-connexion-description')}</Card.Description>
        <Button
          primary
          size="tiny"
          style={{ width: '100%', marginTop: 4 }}
          target="_blank"
          href="https://utilities.clickmeeting.com/connection-tester"
        >
          <Icon name="microphone" />
          {getString('appointments.test-my-connexion')}
        </Button>
      </Card.Content>
      <Card.Content extra style={{ color: 'black' }}>
        <Button
          primary
          size="large"
          icon
          style={{ width: '100%', backgroundColor: '#22ba46' }}
          target="_blank"
          labelPosition="right"
          // TODO: get it directly from platform-service ?
          href={`${endpoints.meetings}/a/c/${collection}/${_id}/u/${store.userId}`}
        >
          <Icon name="play" />
          {getString('appointments.access-room')}
        </Button>
      </Card.Content>
    </Card>
  );
};

AccessMeetingRoomBlock.propTypes = {
  appointment: PropTypes.shape(appointmentProptypes).isRequired,
};

export default AccessMeetingRoomBlock;
