import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Dropdown } from 'semantic-ui-react';

const SearchCategoryLayout = ({ name, children }) => {
  return (
    <>
      <Dropdown.Header>{name}</Dropdown.Header>
      <Divider />
      <>{children}</>
    </>
  );
};

SearchCategoryLayout.propTypes = {
  name: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default SearchCategoryLayout;
