import React from 'react';
import PropTypes from 'prop-types';
import padStart from 'lodash/padStart';
import { dateProptypes } from '../../propTypes';
import { useTimeLeft } from '../../home/blocks/CountdownBlock';
import { fromNow } from '../../utils/dateUtils';
import { localeFormat } from '../../utils/date';
import { getString } from '../../utils';

const translationPrefix = 'workshops.workshop';

function padTime(string) {
  return padStart(string, 2, '0');
}

function getLabel(string, date, format) {
  return getString(`${translationPrefix}.${string}`, { time: localeFormat(date, format) });
}

function getCountdownText(date, timeLeft) {
  if (!timeLeft) return '';
  const { calendarDays, hours } = timeLeft;
  if (calendarDays > 1) return getLabel('live-day-at', date, 'PPPPp');
  if (calendarDays === 1) return getLabel('live-tomorrow-at', date, 'p');
  if (hours > 2) return getLabel('live-at', date, 'p');
  return getString(`${translationPrefix}.live-fromNow`, { time: fromNow(date) });
}

function TextCountdown(props) {
  const { date, text, mode } = props;
  const timeLeft = useTimeLeft(date);
  if (!timeLeft) return null; // Auto-hide
  const { days, hours, minutes, seconds } = timeLeft;
  const className = `poster__countdown ${mode}`;

  if (text) {
    return <div className={className}>{text}</div>;
  }

  switch (mode) {
    case 'absolute': {
      return (
        <div className={className}>
          {padTime(days)}:{padTime(hours)}:{padTime(minutes)}:{padTime(seconds)}
        </div>
      );
    }
    case 'relative': {
      return <div className={className}>{fromNow(date)}</div>;
    }
    default: {
      return <div className={className}>{getCountdownText(date, timeLeft)}</div>;
    }
  }
}

TextCountdown.defaultProps = {
  date: undefined,
  mode: 'default',
  text: undefined,
};

TextCountdown.propTypes = {
  date: dateProptypes,
  mode: PropTypes.oneOf(['absolute', 'default', 'relative']),
  text: PropTypes.string,
};

export default TextCountdown;
