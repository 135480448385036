import { fetchParticipants } from '../../participants/store/participants.actions';
import store from '../../shared/Store';
import { fetchSponsors } from '../../sponsors/store/sponsors.actions';
import { fetchUsersByCollection } from './users.actions';

// TODO: throttle/memoize
export function fetchUserCollections(collections) {
  collections.forEach((collection) => {
    switch (collection) {
      case 'participants':
        return store.reduxStore.dispatch(fetchParticipants());
      case 'sponsors':
        return store.reduxStore.dispatch(fetchSponsors());
      default:
        return store.reduxStore.dispatch(fetchUsersByCollection(collection));
    }
  });
}
