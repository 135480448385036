import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { parseISO } from 'date-fns';
import './DaysContainer.scss';
import { Menu } from 'semantic-ui-react';
import { localeFormat } from '../utils/date';

function DaysContainer(props) {
  const { days } = props;
  const [activeItem, setActiveItem] = useState(days.length > 0 && days[0]);
  return (
    <Menu secondary className="DaysContainer">
      {days.map((day) => {
        const isSelected = activeItem === day;
        return (
          <Menu.Item
            key={day}
            className={cx('day', { selected: isSelected })}
            name={day}
            active={isSelected}
            onClick={(_e, { name }) => setActiveItem(name)}
          >
            {localeFormat(day && parseISO(day), 'PPPP')}
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

DaysContainer.defaultProps = {
  days: [],
};

DaysContainer.propTypes = {
  days: PropTypes.arrayOf(PropTypes.string),
};

export default DaysContainer;
