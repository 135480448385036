/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import { bem } from '../../../core/design/bem';

const overlayCSS = bem('screen-overlay');

function blockEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}

const ScreenOverlay = ({ visible, onClick }) => (
  <div
    className={overlayCSS.state({ visible }).toString()}
    onClick={visible ? onClick : undefined}
  />
);

ScreenOverlay.defaultProps = {
  onClick: blockEvent,
  visible: false,
};

ScreenOverlay.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};
export default ScreenOverlay;
