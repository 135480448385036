import { useToggle } from 'ahooks';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { CmsBlocks } from '../../cms/CmsScreen';
import useTranslations from '../../hooks/useTranslations';
import { getString } from '../../utils';

const LegalNoticeModal = (props) => {
  const { className, blocks, header, onClick } = props;
  const { t } = useTranslations('legal-notice-modal');
  const [check, { toggle }] = useToggle(false);

  return (
    <Modal className={cx('login--legal-notice-modal', className)} open>
      <Modal.Header>{header || t('header-label')}</Modal.Header>
      <Modal.Content scrolling style={{ paddingTop: 5 }}>
        <CmsBlocks blocks={blocks} />
      </Modal.Content>
      <Modal.Actions
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Checkbox
          label={t('checkbox-label')}
          onClick={(e, { checked }) => toggle(checked)}
          value={check}
        />
        <Button disabled={!check} primary onClick={onClick}>
          {getString('btn.accept')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
LegalNoticeModal.defaultProps = {
  className: undefined,
  header: undefined,
};
LegalNoticeModal.propTypes = {
  blocks: PropTypes.array.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default LegalNoticeModal;
