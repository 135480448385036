import React from 'react';
import CdnImage from '../../../../components/CdnImage';
import { useDesignConfig } from '../../../../config/design.context';

const LoginFooter = (): JSX.Element | null => {
  const design = useDesignConfig();
  const { loginFooter } = design;

  if (!loginFooter) return null;
  return <CdnImage className="LoginFooter" src={loginFooter} />;
};

export default LoginFooter;
