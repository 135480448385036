import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useConfig } from '../../config/config.context';
import { useSelectedAchievements } from '../../core/achievements/achievements.hook';
import { useMe } from '../../profile/hooks';
import { achievementsPropTypes, containerTypePropTypes } from '../../propTypes';
import { replaceValues } from '../../utils/stringUtils';
import AchievementBadge from '../AchievementBadge';
import BlockContainer from '../BlockContainer';
import './HtmlBlock.scss';

function HtmlBlock(props) {
  const { id, achievements, title, content, containerType, _id } = props;

  const user = useMe();
  const { domain } = useConfig();
  const { ids: achievementsIds } = achievements || {};
  const blockAchievements = useSelectedAchievements(achievementsIds);
  if (!content) return null;

  return (
    <BlockContainer id={id} type={containerType} header={title} className={cx('block--html', _id)}>
      {blockAchievements && (
        <div className="achievements-badge-container" style={{ display: 'flex' }}>
          {blockAchievements.map((a) => (
            <AchievementBadge
              key={a._id}
              className={cx('achievement-image', a._id)}
              achievement={a}
              height={40}
            />
          ))}
        </div>
      )}
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: replaceValues(content, { user, domain }) }} />
    </BlockContainer>
  );
}

HtmlBlock.defaultProps = {
  achievements: undefined,
  content: undefined,
  containerType: 'segment-light',
  id: undefined,
  title: undefined,
};

HtmlBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  achievements: achievementsPropTypes,

  content: PropTypes.string,
  containerType: containerTypePropTypes,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default HtmlBlock;
