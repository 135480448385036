import { useMount } from 'ahooks';
import { mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUsers } from '../core/users/users.select';
import { fetchUserCollections } from '../core/users/users.utils';
import { throttleDispatchFetch } from '../utils';
import { useOrderBy } from '../utils/hooks';

const throttledFetch = throttleDispatchFetch(fetchUserCollections);

const Query = ({ select, from, orderBy, children: childFunction }) => {
  const { collection } = from;
  const { users = [] /* , loaded */ } = useSelector((state) => selectUsers(state, [collection]));
  useMount(() => throttledFetch([collection]));

  const items = useOrderBy(users, orderBy);

  function mapSelect(entity) {
    if (!select) return { ...entity, entity };
    return {
      kind: entity.kind,
      collection: entity.collection,
      ...mapValues(select, (v) => entity[v]),
      entity,
    };
  }

  return childFunction(items.map(mapSelect));
};

Query.defaultProps = {
  orderBy: undefined,
  select: undefined,
};

Query.propTypes = {
  select: PropTypes.object,
  from: PropTypes.shape({
    kind: PropTypes.string,
    collection: PropTypes.string,
  }).isRequired,
  orderBy: PropTypes.arrayOf(
    PropTypes.shape({ field: PropTypes.string, order: PropTypes.oneOf(['asc', 'desc']) }),
  ),
  children: PropTypes.func.isRequired,
};

export default Query;
