import get from 'lodash/get';
import max from 'lodash/max';
import { useEffect } from 'react';
import '../SocialWall.scss';
import { useUserSocialWallDocument } from '../useSocialWallData';

function useLastSeen(messages) {
  const { user, setUser } = useUserSocialWallDocument();
  useEffect(() => {
    if (messages.length > 0) {
      const lastDate = max(messages.map((msg) => msg.createdAt));
      if (lastDate !== get(user, 'lastSeen')) {
        setUser({ lastSeen: lastDate }, { merge: true });
      }
    }
  }, [messages, user]);
}

export default useLastSeen;
