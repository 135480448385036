import { useBoolean } from 'ahooks';
import cx from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { CmsBlocks } from '../../cms/CmsScreen';
import { getParameterByName } from '../../shared/Store';

const FooterModal = ({ modalName, isOpen, onClose, className, blocks, header, trigger }) => {
  const [open, { setTrue: setOpen, setFalse: setClose }] = useBoolean(
    isOpen || getParameterByName('modal') === modalName,
  );
  return (
    <Modal
      className={cx('footer-modal', className)}
      closeIcon
      onClose={() => {
        setClose();
        onClose();
      }}
      onOpen={setOpen}
      open={open}
      trigger={trigger}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content scrolling style={{ paddingTop: 5 }}>
        <CmsBlocks blocks={blocks} />
      </Modal.Content>
    </Modal>
  );
};
FooterModal.defaultProps = {
  className: undefined,
  isOpen: false,
  modalName: undefined,
  onClose: noop,
};
FooterModal.propTypes = {
  className: PropTypes.string,
  blocks: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  header: PropTypes.string.isRequired,
  modalName: PropTypes.string,
  onClose: PropTypes.func,
  trigger: PropTypes.node.isRequired,
};
export default FooterModal;
