/* eslint-disable class-methods-use-this */
import store from '../../shared/Store';
import platformService from './platform.service';

const { endpoints } = window.__DATA__;

class SocialWallService {
  async postMessage(post) {
    return platformService.securePost(
      `${endpoints.platform}/social-wall/users/${store.userId}/posts`,
      post,
    );
  }

  async patchMessage(postId, patch) {
    return platformService.securePatch(
      `${endpoints.platform}/social-wall/users/${store.userId}/posts/${postId}`,
      patch,
    );
  }

  async deleteMessage(postId) {
    return platformService.secureDelete(
      `${endpoints.platform}/social-wall/users/${store.userId}/posts/${postId}`,
    );
  }
}

export default new SocialWallService();
