import React from 'react';
import { replaceValues } from '../../utils/stringUtils';

export type Meta = { _id: string; type: string; name: string; content: string };

export type MetadataItem = any;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function outputMetadata(meta: Meta, item?: MetadataItem) {
  const { _id, type = 'meta', name, content: rawContent } = meta;
  if (!rawContent) return null;
  const content = item ? replaceValues(rawContent, { item }) : rawContent;
  switch (type) {
    case 'meta':
      if (!name) return undefined;
      if (name === 'title') return <title key={_id}>{content}</title>;
      // eslint-disable-next-line react/prop-types
      if (name.startsWith('og')) return <meta key={_id} property={name} content={content} />;
      return <meta key={_id} name={name} content={content} />;
    case 'style':
      return (
        <style key={_id} type="text/css">
          {content}
        </style>
      );
    case 'link':
      return <link key={_id} rel="stylesheet" href={content} />;
    case 'script':
      return (
        <script key={_id} type={name || 'text/javascript'}>
          {content}
        </script>
      );
    default:
      return null;
  }
}
