import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { useConfig } from '../../../config/config.context';
import { useScreensConfig } from '../../../config/screens.context';
import { trackModal } from '../../../core/trackers';
import { useMe } from '../../../profile/hooks';

export function useWorkshopToRegister(workshopId, workshops, workshopSessions, saveOnSessionsOnly) {
  const { defaultTimezone } = useConfig();
  const user = useMe();
  const events = useSelector(userAgenda);
  const registration = events.find((e) => e.workshopId === workshopId);
  return useMemo(() => {
    if (registration?.workshopId) {
      // if user has registered for a session of the current workshop
      return workshopSessions.find((s) => s._id === registration._id);
    }
    if (workshopSessions.length > 0) {
      const timezone = user.timezone || defaultTimezone;
      return workshopSessions.find((s) => s.timezone === timezone) || workshopSessions[0];
    }
    if (saveOnSessionsOnly) return undefined;
    return workshops.find((w) => w._id === workshopId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopId, workshops, workshopSessions, registration, saveOnSessionsOnly, user]);
}

export function useTrackingModal(modalConfig, payload) {
  const screens = useScreensConfig();
  useEffect(() => {
    if (screens) {
      trackModal(modalConfig, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfig, payload, !!screens]);
}
