import keyBy from 'lodash/keyBy';
import { updateOrAppendItem } from '../../utils/arrayUtils.ts';
import { SET_ALL, SET_BY_ID } from './programs.types';

const initialState = {
  programs: [],
  programsById: {},
};

function ensureKind(program) {
  if (!program.kind) return { ...program, kind: 'users' };
  return program;
}

export default function reduceProgram(state = initialState, action) {
  switch (action.type) {
    case SET_ALL: {
      const programs = action.programs.map(ensureKind);
      return { programs, programsById: keyBy(programs, '_id') };
    }
    case SET_BY_ID: {
      // Update a given program
      const programs = updateOrAppendItem(state.programs, action.program);
      return { programs, programsById: keyBy(programs, '_id') };
    }
    default:
      return state;
  }
}
