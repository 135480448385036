/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { bem } from '../../../../core/design/bem';
import CdnImage from '../../../CdnImage';
import { useFileTracking } from '../../FilesBlock';
import PDFModal from '../../FilesBlock/components/PDFModal';
import { Image } from '../../types';
import { isPDF, isVideo } from '../../utils';
import { CarouselImageProps } from '../types';
import VideoVariant from './VideoVariant';

const css = bem('CarouselBlock');

type DocumentVariantProps = {
  imageProps?: CarouselImageProps;
  thumbnail?: Image;
  title?: string;
  value: { uri: string };
};

const DocumentVariant = (props: DocumentVariantProps): JSX.Element | null => {
  const { title, value, thumbnail, imageProps } = props;
  const [showPdf, setShowPdf] = useState(false);
  const { onView } = useFileTracking({});
  function handleClick() {
    if (isPDF(value?.uri)) {
      onView(value);
      setShowPdf(true);
    }
  }

  if (isVideo(value?.uri)) {
    return <VideoVariant {...props} />;
  }

  return (
    <>
      <div className={css('document')} onClick={handleClick}>
        <CdnImage src={thumbnail} maxWidth={600} maxHeight={338} srcSet {...imageProps} />
        <div className="content">{title}</div>
      </div>
      {showPdf && <PDFModal file={value} onClose={() => setShowPdf(false)} />}
    </>
  );
};

DocumentVariant.defaultProps = {
  imageProps: undefined,
  thumbnail: undefined,
  title: undefined,
};

export default DocumentVariant;
