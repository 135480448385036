import flatten from 'lodash/flatten';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSyncedWorkshops } from '../../workshops/store/workshops.hooks';
import FilesBlock from './FilesBlock';

function WorkshopFilesBlock(props) {
  const workshops = useSyncedWorkshops();
  const files = useMemo(() => flatten(workshops.map((w) => w.files || [])), [workshops]);
  return <FilesBlock {...props} files={files} />;
}

WorkshopFilesBlock.defaultProps = {
  subtitle: undefined,
  title: undefined,
};

WorkshopFilesBlock.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default WorkshopFilesBlock;
