/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { ensureDate } from '../../utils/date';
import { html2text } from '../../utils/stringUtils';

// BEGIN:VCALENDAR
// VERSION:2.0
// CALSCALE:GREGORIAN
// PRODID:adamgibbons/ics
// METHOD:REQUEST
// BEGIN:VEVENT
// UID:3c6d44e8-79a7-428d-acac-9586c9e06e5c
// SUMMARY:Lunch
// DTSTAMP:20180210T093900Z
// DTSTART:20180115T191500Z
// DURATION:PT45M
// DURATION:PT1H30M
// END:VEVENT
// END:VCALENDAR

export function formatDate(date) {
  if (!date) return null;
  // Force "Z" string, if not moment injects +00:00
  return moment(ensureDate(date)).utc().format('YYYYMMDD[T]HHmmss[Z]');
}

function encode(string) {
  if (!string) return string;
  return string.replace(/,/g, '\\,').replace(/\n/g, '\\n');
}

function foldLine(line) {
  const parts = [];
  let length = 75;
  // const theLine = ...line;
  while (line.length > length) {
    parts.push(line.slice(0, length));
    // eslint-disable-next-line no-param-reassign
    line = line.slice(length);
    length = 74;
  }
  parts.push(line);
  return parts.join('\n\t');
}

function buildReminder(reminderTime) {
  if (Array.isArray(reminderTime)) {
    return reminderTime.map(buildReminder).join('\n');
  }
  return `BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:REMINDER
TRIGGER:${reminderTime}
END:VALARM`;
}

function buildAttendees(attendees) {
  function encodeAttendee(attendee) {
    const name = `${attendee.firstName} ${attendee.lastName}`.trim();
    return `ATTENDEE;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=FALSE;CN=${encode(
      name,
    )}:mailto:${attendee.email}`;
  }
  return attendees.map(encodeAttendee).join('\n');
}

function buildOrganizer(organizer, email) {
  if (email) {
    return `ORGANIZER;CN="${encode(organizer)}":mailto:${email}`;
  }
  return `ORGANIZER;CN="${encode(organizer)}"`;
  // return `ORGANIZER;${encode(value)}`;
}

export function generateICSString(ics) {
  const {
    method = 'PUBLISH',
    content,
    uid,
    title,
    description,
    descriptionHtml,
    startDate,
    endDate,
    DTSTAMP,
    organizer,
    email,
    location,
    reminderTime,
    status,
    url,
    transp = 'opaque',
    // attendees,
  } = ics;
  if (content) return content; // Bypass

  const outlookDescription = descriptionHtml
    ? `<!DOCTYPE HTML PUBLIC ""-//W3C//DTD HTML 3.2//EN""><HTML><BODY>${descriptionHtml}</BODY></HTML>`
    : null;

  const entries = [
    ['UID', uid],
    ['DESCRIPTION', description?.includes?.('<') ? html2text(description) : description],
    ['X-ALT-DESC;FMTTYPE=text/html', outlookDescription],
    ['ORGANIZER', organizer || null],
    ['LOCATION', location],
    ['DTSTAMP', formatDate(DTSTAMP || new Date())],
    ['DTSTART', formatDate(startDate)],
    ['DTEND', formatDate(endDate)],
    ['STATUS', status],
    ['SUMMARY', title],
    ['TRANSP', transp],
    ['REMINDER', reminderTime],
    ['URL', url],
    // ["ATTENDEES", attendees && attendees.length > 0 ? attendees : [defaultAttendee || {}]],
  ].filter(([key, value]) => value);

  const eventText = entries
    .map(([key, value]) => {
      if (key === 'ATTENDEES') return buildAttendees(value);
      if (key === 'REMINDER') return buildReminder(value);
      if (key === 'ORGANIZER') return buildOrganizer(value, email);
      return `${key}:${encode(value)}`;
    })
    .join('\n')
    .split('\n') // Re-split to ensure line length
    .map(foldLine)
    .join('\n');

  return `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:appcraftevents/ics
METHOD:${method}
BEGIN:VEVENT
${eventText}
END:VEVENT
END:VCALENDAR`.replace(/\n/g, '\r\n'); // Force CRLF
}
