import cx from 'classnames';
import React from 'react';
import './RatioContainer.scss';

// eslint-disable-next-line react/prop-types
function RatioContainer({ children, ratio, style, className, ...props }) {
  const calculatedStyle = {
    ...style,
    paddingBottom: `${(100 / ratio).toFixed(3)}%`,
  };
  return (
    <div {...props} style={calculatedStyle} className={cx(className, 'RatioContainer')}>
      <div>{children}</div>
    </div>
  );
}

export default RatioContainer;
