/* eslint-disable max-len */
// Forked from https://github.com/jasonleibowitz/react-add-to-calendar-hoc/blob/master/src/lib/utils.js
import moment, { ISO_8601 } from 'moment';
import platformService from '../../core/services/platform.service';
import { SHARE_SITES } from './enums';
import { formatDate, generateICSString } from './icsUtils';

/**
 * Converts Date String with UTC timezone to date consumable by calendar
 * apps. Changes +00:00 to Z.
 * @param {string} Date in YYYYMMDDTHHmmssZ format
 * @returns {string} Date with +00:00 replaceed with Z
 */
// export const formatDate = (date) => date && date.replace('+00:00', 'Z');

function digits2(number) {
  if (number < 10) return `0${number}`;
  return number.toString();
}

export const formatDuration = (duration) => {
  if (typeof duration === 'string') return duration;

  const hours = Math.floor(duration);
  const minutes = Math.floor((duration - hours) * 60);
  return digits2(hours) + digits2(minutes);
};

export function computeHoursDuration(startDate, endDate) {
  const duration = moment.duration(moment(endDate, ISO_8601).diff(moment(startDate, ISO_8601)));
  return duration.asHours();
}

/**
 * Tests provided UserAgent against Known Mobile User Agents
 * @returns {bool} isMobileDevice
 */
export const isMobile = () =>
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
    window.navigator.userAgent || window.navigator.vendor || window.opera,
  );

/**
 * Tests userAgent to see if browser is IE
 * @returns {bool} isInternetExplorer
 */
export const isInternetExplorer = () =>
  /MSIE/.test(window.navigator.userAgent) || /Trident/.test(window.navigator.userAgent);

export const escapeICSDescription = (description) =>
  description.replace(/(\r?\n|<br ?\/?>)/g, '\\n');

function qs(params) {
  return Object.keys(params)
    .filter((key) => params[key] !== '' && params[key] !== undefined)
    .map((key) => {
      return `${key}=${encodeURIComponent(params[key] ?? '')}`;
    })
    .join('&');
}

/**
 * Takes an event object and returns a Google Calendar Event URL
 * @param {string} event.description
 * @param {string} event.endDate
 * @param {string} event.location
 * @param {string} event.startDate
 * @param {string} event.title
 * @returns {string} Google Calendar Event URL
 */
export const googleShareUrl = ({
  description: details,
  endDate,
  location,
  startDate,
  timezone: ctz,
  title: text,
}) =>
  `https://calendar.google.com/calendar/render?${qs({
    action: 'TEMPLATE',
    dates: `${formatDate(startDate)}/${formatDate(endDate)}`,
    ctz,
    location,
    text,
    details,
  })}`;

/**
 * Takes an event object and returns a Yahoo Calendar Event URL
 * @param {string} event.description
 * @param {string} event.location
 * @param {string} event.endDate
 * @param {string} event.startDate
 * @param {string} event.title
 * @returns {string} Yahoo Calendar Event URL
 */
export const yahooShareUrl = ({
  description: desc,
  // eslint-disable-next-line camelcase
  location: in_loc,
  startDate,
  endDate,
  title,
}) =>
  `https://calendar.yahoo.com/?${qs({
    v: 60,
    view: 'd',
    type: 20,
    title,
    st: formatDate(startDate),
    dur: computeHoursDuration(startDate, endDate),
    desc,
    in_loc,
  })}`;

/**
 * Takes an event object and a type of URL and returns either a calendar event
 * URL or the contents of an ics file.
 * @param {string} event.description
 * @param {string} event.duration
 * @param {string} event.endDatetime
 * @param {string} event.location
 * @param {string} event.startDatetime
 * @param {string} event.title
 * @param {enum} type One of SHARE_SITES from ./enums
 */
export const buildShareUrl = (
  {
    description = '',
    descriptionHtml = '',
    location = '',
    timezone = '',
    title = '',
    url = '',
    startDate,
    endDate,
    reminderTime,
    forceLink,
  },
  type,
) => {
  const data = {
    description,
    descriptionHtml,
    location,
    startDate,
    endDate,
    timezone,
    reminderTime,
    url,
    title,
  };

  switch (type) {
    case SHARE_SITES.GOOGLE:
      return googleShareUrl(data);
    case SHARE_SITES.YAHOO:
      return yahooShareUrl(data);
    default: {
      const ics = generateICSString(data);
      if (forceLink) {
        return platformService.buildICSUrl(ics);
      }
      return ics;
    }
  }
};
