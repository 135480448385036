/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';
import FloatingChat from '../../../components/Chat/FloatingChat';
import store from '../../../shared/Store';
import { getString } from '../../../utils';
import { OneToOneChat as OneToOne } from './OneToOneChat.namespace';

const OneToOneChatBlock = ({
  sponsor,
  sponsorId: givenSponsorId,
  translationPrefix,
  type: sponsorCollection,
}) => {
  const sponsorId = givenSponsorId || sponsor?._id;
  const self = useSelector((state) => state.user?.user);
  const ownUserId = self?._id;
  const ownProfile = useMemo(() => pick(self, 'firstName', 'lastName', 'thumbnail'), [self]);

  // TODO allow customizing popupDelay (chatbot) from higher-level config
  const popupDelay = 3500;

  const chat = OneToOne.useChat(
    store.eventId,
    sponsorCollection,
    sponsorId || sponsor._id,
    ownUserId,
    ownProfile,
  );

  const { isAvailable, messages, addMessage, removeMessage } = chat;

  return !isAvailable ? null : (
    <FloatingChat
      title={getString(`${translationPrefix}.chat-one-to-one.title`, sponsor)}
      messages={messages}
      headerMessage={{
        text: getString(`${translationPrefix}.chat-one-to-one.header-message`, sponsor),
      }}
      me={ownUserId}
      sponsorImage={sponsor.logo}
      onDelete={removeMessage}
      onSendMessage={addMessage}
      popupDelay={popupDelay}
    />
  );
};

OneToOneChatBlock.defaultProps = {
  sponsor: {},
  translationPrefix: 'sponsors.sponsor',
  type: 'sponsors',
};

OneToOneChatBlock.propTypes = {
  sponsor: PropTypes.shape({
    logo: PropTypes.shape({ uri: PropTypes.string }),
    _id: PropTypes.string,
  }),
  sponsorId: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string,
  type: PropTypes.string,
};

export default OneToOneChatBlock;
