import get from 'lodash/get';
import React from 'react';
import { Menu } from 'semantic-ui-react';

import { useConfig } from '../../config/config.context';
import { bem } from '../../core/design/bem';
import { useMe } from '../../profile/hooks';

import { handleChangeTimezone } from '../menu/AppSecondaryMenu';

const css = bem('TimezoneTabs');

function TimezoneTabs() {
  const { timezones, defaultTimezone } = useConfig();
  const user = useMe();
  if (!timezones?.length) return null;
  const userTimezone = get(user, 'timezone', defaultTimezone);

  const handleClickItem = (_e, { name }) => {
    handleChangeTimezone(name);
  };
  return (
    <Menu
      style={{ marginTop: 0 }}
      className={css().toString()}
      pointing
      secondary
      fluid
      widths={timezones.length}
    >
      {timezones.map(({ key, value, text }) => (
        <Menu.Item key={key} name={value} active={userTimezone === value} onClick={handleClickItem}>
          {text}
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default TimezoneTabs;
