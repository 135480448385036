/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo } from 'react';
import { Comment } from 'semantic-ui-react';
import WorkshopComment from './WorkshopComment';

type WorkshopCommentType = Record<string, any> & {
  createdAt: string;
  id: string;
  data: Record<string, any>;
};

type WorkshopCommentsProps = {
  isAnonymous?: boolean;
  workshopId: string;
  comments: WorkshopCommentType[];
  maxChars?: number;
  maxComments?: number;
  showUserOnThumbnailClick?: boolean;
};

const WorkshopComments = ({
  comments,
  workshopId,
  isAnonymous,
  showUserOnThumbnailClick,
  maxChars,
  maxComments,
}: WorkshopCommentsProps) => {
  if (comments.length === 0) return null;
  const commentsSlice = maxComments ? comments.slice(0, maxComments) : comments;
  return (
    <Comment.Group className="comments--container">
      {commentsSlice.map((comment) => (
        <WorkshopComment
          key={comment.id}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          comment={comment}
          workshopId={workshopId}
          isAnonymous={isAnonymous}
          showUserOnThumbnailClick={showUserOnThumbnailClick}
          maxChars={maxChars}
        />
      ))}
    </Comment.Group>
  );
};

WorkshopComments.defaultProps = {
  isAnonymous: false,
  maxChars: undefined,
  maxComments: undefined,
  showUserOnThumbnailClick: false,
};

export default memo(WorkshopComments);
