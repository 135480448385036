import get from 'lodash/get';
import { formatFieldValue, formatSearchContent, textSearch } from '../../core/filter/utils';
import { getString } from '../../utils';
// import { replaceValues } from '../../utils/stringUtils';

export const searchKey = Symbol('searchKey');

function formatSearchField(item, fields, searchFields) {
  return formatSearchContent(
    item,
    searchFields.map((field) => fields[field]),
  );
}

export function formatEntryItemIntoSearchItem(fields, key, searchFields) {
  return (item) => {
    return Object.entries(fields).reduce(
      (itemResult, [innerKey, field]) => ({
        ...itemResult,
        [innerKey]: formatFieldValue(item, field),
      }),
      {
        key: `${key}/${item._id}`,
        item,
        [searchKey]: formatSearchField(item, fields, searchFields),
      },
    );
  };
}

export function formatStateIntoSearchData(enabled, fieldsConfig, searchFields) {
  return (state) => {
    if (!enabled) return null;
    return Object.entries(fieldsConfig).reduce((acc, [key, { root, fields }]) => {
      const rawEntries = get(state, root) ?? [];
      return {
        ...acc,
        [key]: {
          name: getString(`search.${key}`) ?? key,
          results: rawEntries.map(formatEntryItemIntoSearchItem(fields, key, searchFields)),
        },
      };
    }, {});
  };
}

export function isAValidItem(searchWords, item) {
  return textSearch(searchWords, item[searchKey]);
}
