import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, List } from 'semantic-ui-react';
import BlockContainer from '../../../components/BlockContainer';
import CdnImage from '../../../components/CdnImage';
import EntityLink from '../../../components/EntityLink';
import { bem } from '../../../core/design/bem';
import { containerProptypes, imageProptypes } from '../../../propTypes';
import { useWorkshopsFromIds } from '../../store/workshops.hooks';
import './WorkshopsBlock.scss';

const block = bem('WorkshopsBlock');
const tag = bem('Field');

export function useFieldLabel(item) {
  const { t } = useTranslation();
  return (field, value) =>
    t(`labels.${item.kind}.${item.collection}.${field}.${value}`, { defaultValue: value });
}

const ItemFieldValue = ({ item, field, value, className, as: Comp = 'span', ...props }) => {
  const { t } = useTranslation();
  const { kind, collection } = item;
  return (
    <Comp {...props} className={cx(className, tag({ kind, collection, field, value }).toString())}>
      {t(`labels.${kind}.${collection}.${field}.${value}`, { defaultValue: value })}
    </Comp>
  );
};

ItemFieldValue.defaultProps = {
  className: undefined,
  as: undefined,
};

ItemFieldValue.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  as: PropTypes.any,
};

const ItemTags = ({ item, field, ...props }) => {
  const value = get(item, field);
  if (!value) return null;

  if (typeof value === 'string')
    return <ItemFieldValue as={Label} {...props} item={item} field={field} value={value} />;
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((v) => (
          <ItemFieldValue as={Label} {...props} item={item} field={field} value={v} />
        ))}
      </>
    );
  }
  return null;
};

ItemTags.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};

const WorkshopsBlock = ({ className, container, header, ids, image }) => {
  const workshops = useWorkshopsFromIds(ids);
  if (!workshops || workshops.length === 0) return null;

  const { field = 'image', ...imageProps } = image || {};
  return (
    <BlockContainer className={className} header={header} {...container}>
      <List divided relaxed>
        {workshops.map((item) => (
          <List.Item
            key={item._id}
            className={block('Item').toString()}
            as={EntityLink}
            entity={item}
          >
            {get(item, field) && (
              <CdnImage src={get(item, field)} maxWidth={60} srcSet {...imageProps} />
            )}
            <List.Content>
              <List.Header>{item.title}</List.Header>
              <List.Description>
                <ItemTags item={item} field="category" size="tiny" />
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </BlockContainer>
  );
};

WorkshopsBlock.defaultProps = {
  container: undefined,
  className: undefined,
  image: undefined,
  header: undefined,
  ids: undefined,
};

WorkshopsBlock.propTypes = {
  container: containerProptypes,
  className: PropTypes.string,
  image: imageProptypes,
  header: PropTypes.string,
  ids: PropTypes.arrayOf(PropTypes.string),
};

export default WorkshopsBlock;
