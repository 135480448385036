import { toast } from 'react-toastify';
import { takeEvery } from 'redux-saga/effects';
import { NOTIFY } from './notifications.types';

function* triggerNotifications({ payload: { text, options } }) {
  yield toast(text, options);
}

function* notificationsSagas() {
  yield takeEvery(NOTIFY, triggerNotifications);
}

export default notificationsSagas;
