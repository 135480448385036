/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Icon, List } from 'semantic-ui-react';
import CdnImage from '../../components/CdnImage';
import BlockContainer from '../../components/BlockContainer';
import { TimeDisplay } from '../../components/DateTimeDisplay';
import EntityLink from '../../components/EntityLink';
import { containerTypePropTypes } from '../../propTypes';
import { getString } from '../../utils';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { getClassName } from '../../workshops/utils';
import { userAgenda } from '../store/agenda.selectors';
import './UserAgendaBlock.scss';
import WorkshopActions from '../../workshops/components/WorkshopActions';
import UserRegistrationModal from '../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import { entityForEvent } from '../../utils/hooks';

const translationPrefix = 'home';

export function workshopPath(workshop) {
  return `/schedule/${workshop.category}/${workshop.slug || workshop._id}`;
}

const SessionItem = ({ event, clickableSession, showImages, ...rest }) => {
  const { actions } = rest;
  const hasActions = actions && actions.length > 0;
  const { title, startDate, endDate, image, timezone, workshopDuration: duration } = event;
  const [isOpen, setIsOpen] = useState(false);
  function linkProps() {
    if (!clickableSession) return { as: 'div' };
    return { as: EntityLink, entity: entityForEvent(event) };
  }

  return (
    <>
      <List.Item className={`item--workshop ${getClassName(event)}`} {...linkProps()}>
        {image && showImages && (
          <div className="image">
            <CdnImage
              src={image}
              maxWidth={130}
              style={{ cursor: hasActions ? 'pointer' : 'inherit' }}
            />
            {hasActions && (
              <div className="overlay">
                <WorkshopActions actions={actions} workshop={event} onClick={setIsOpen} />
              </div>
            )}
          </div>
        )}

        {!showImages && <List.Icon name="circle" size="tiny" verticalAlign="top" />}
        <List.Content>
          <Header className="title">{title}</Header>
          <List.Description className="date">
            <TimeDisplay startDate={startDate} endDate={endDate} timezone={timezone} />
            {duration && <div className="duration">{duration}</div>}
          </List.Description>
        </List.Content>
      </List.Item>
      {isOpen && (
        <UserRegistrationModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

SessionItem.defaultProps = {
  clickableSession: true,
  showImages: false,
};
SessionItem.propTypes = {
  event: PropTypes.shape({
    category: PropTypes.string,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
  clickableSession: PropTypes.bool,
  showImages: PropTypes.bool,
};

const SessionDay = ({ day, events, clickableSession, showImages, ...rest }) => {
  const { actions } = rest;
  const { t } = useTranslation();
  return (
    <>
      <Header as="h4">{t('common.date', { date: day, context: 'user_agenda' })}</Header>
      <List relaxed animated={clickableSession}>
        {events.map((event) => (
          <SessionItem
            key={event._id}
            event={event}
            showImages={showImages}
            actions={actions}
            clickableSession={clickableSession}
          />
        ))}
      </List>
    </>
  );
};

SessionDay.defaultProps = {
  clickableSession: true,
  showImages: false,
};

SessionDay.propTypes = {
  day: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  clickableSession: PropTypes.bool,
  showImages: PropTypes.bool,
};

export const GroupedUserSessions = ({ sessions, ...rest }) => {
  const { days, eventsByDay } = groupEventsByDays(sessions);
  return (
    <>
      {days.map((day) => (
        <SessionDay key={day} day={day} events={eventsByDay[day]} {...rest} />
      ))}
    </>
  );
};

GroupedUserSessions.propTypes = {
  sessions: PropTypes.array.isRequired,
};

const UserAgendaBlock = ({ header, containerType, clickableSession }) => {
  const sessions = useSelector(userAgenda);
  return (
    <BlockContainer className="block--user-agenda" type={containerType} header={header}>
      <div>
        <GroupedUserSessions sessions={sessions} clickableSession={clickableSession} />
        <Link to="/profile" className="view-all">
          {getString(`${translationPrefix}.view-all`)} <Icon name="angle right" />
        </Link>
      </div>
    </BlockContainer>
  );
};

UserAgendaBlock.defaultProps = {
  containerType: 'segment',
  header: undefined,
  clickableSession: true,
};

UserAgendaBlock.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  clickableSession: PropTypes.bool,
};

export default UserAgendaBlock;
