import { useCallback, useState } from 'react';
import { useConfig } from '../config/config.context';

export function useLoginConfig() {
  const config = useConfig();
  return config?.screens?.login || {};
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}
