import PropTypes from 'prop-types';
import React from 'react';
import values from 'lodash/values';
import PublicChatBlock from './PublicChatBlock';
import OneToOneChatBlock from './OneToOneChatBlock';

const ChatModes = {
  ONE_TO_ONE: 'one-to-one',
  PUBLIC: 'public',
};

const chatComponents = {
  [ChatModes.PUBLIC]: PublicChatBlock,
  [ChatModes.ONE_TO_ONE]: OneToOneChatBlock,
};

const SponsorChat = ({ type, sponsorId, sponsor, translationPrefix, mode }) => {
  const ChatComponent = chatComponents[mode] || chatComponents[ChatModes.PUBLIC];

  return (
    <ChatComponent
      type={type}
      sponsorId={sponsorId}
      sponsor={sponsor}
      translationPrefix={translationPrefix}
    />
  );
};

SponsorChat.defaultProps = {
  sponsor: {},
  translationPrefix: 'sponsors.sponsor',
  type: 'sponsors',
  mode: ChatModes.PUBLIC,
};

SponsorChat.propTypes = {
  sponsor: PropTypes.shape({ logo: PropTypes.shape({ uri: PropTypes.string }) }),
  sponsorId: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string,
  type: PropTypes.string,
  mode: PropTypes.oneOf(values(ChatModes)),
};

export default SponsorChat;
