/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { bem } from '../../../../core/design/bem';
import VideoCard from '../../../video/VideoCard';

const css = bem('CarouselBlock');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VideoVariant = (video: any): JSX.Element | null => {
  return (
    <div className={css('video')}>
      <VideoCard video={video} />
    </div>
  );
};

VideoVariant.defaultProps = {
  title: undefined,
  video: undefined,
  thumbnail: undefined,
};

export default VideoVariant;
