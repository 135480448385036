import { useEffect, useRef } from 'react';

const useChatScroll = (messages) => {
  const scrollStateCache = useRef({
    awaitingNewMessages: true,
  });
  const scrollElementRef = useRef();
  useEffect(() => {
    const element = scrollElementRef.current;
    if (element && scrollStateCache.current.awaitingNewMessages) {
      element.scrollTop = element.scrollHeight;
    }

    const onScroll = () => {
      const maxScroll = element.scrollHeight - element.offsetHeight;
      const scrolled = element.scrollTop < maxScroll;

      scrollStateCache.current.awaitingNewMessages = !scrolled;
    };

    element.addEventListener('scroll', onScroll);

    return () => {
      element.removeEventListener('scroll', onScroll);
    };
  }, [messages]);

  return scrollElementRef;
};

export default useChatScroll;
