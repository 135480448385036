import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useMedia } from 'react-media';
import { Card } from 'semantic-ui-react';
import { eventTags } from '../../../core/trackers/events';
import { ClickableImage } from '../../../headers/blocks/Banner';
import { logoArrayPropTypes, logoPropType } from '../../../propTypes';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import { cdnImagePropTypes } from '../../CdnImage';
import ItemCardGroup from '../../ItemCardGroup';
import Query from '../../Query';
import CarouselBlock from '../CarouselBlock';
import './LogosBlock.scss';

const LogoCard = (props) => {
  const { item, trackClick, maxWidth, maxHeight, func } = props;
  const { image, link, entity } = item;
  return (
    <Card>
      <ClickableImage
        image={image}
        link={link}
        entity={entity}
        trackClick={trackClick}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
      />
    </Card>
  );
};
LogoCard.defaultProps = {
  trackClick: eventTags.CTA_GO_TO,
  maxWidth: undefined,
  maxHeight: 500,
  func: 'fit',
};
LogoCard.propTypes = {
  item: logoPropType.isRequired,
  trackClick: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  func: cdnImagePropTypes.func,
};

const Logos = (props) => {
  const {
    _id,
    containerType,
    header,
    logos,
    itemsPerRow,
    groupBy,
    mobileItemsPerRow,
    ...rest
  } = props;
  const { container, mobile = {}, logoProps = {} } = rest;
  const { variant: mobileVariant, carousel } = mobile;
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (!logos) return null;
  const itemsPerLine = matches.desktop ? itemsPerRow : mobileItemsPerRow || itemsPerRow;
  if (!matches.desktop && mobileVariant === 'carousel') {
    return (
      <CarouselBlock
        _id={_id}
        carousel={carousel}
        container={container}
        items={logos}
        component={(logo) => <LogoCard {...logoProps} item={logo} />}
      />
    );
  }
  return (
    <BlockContainer
      className={cx('block--logos', _id)}
      header={header}
      type={containerType}
      {...container}
    >
      <ItemCardGroup
        className={cx('logos', { 'mobile-multiple': matches.mobile && mobileItemsPerRow > 1 })}
        centered
        groupClassName="logos"
        items={logos}
        itemsPerRow={itemsPerLine}
        groupBy={groupBy}
        component={({ item }) => <LogoCard {...logoProps} item={item} />}
        style={{ display: matches.mobile && mobileItemsPerRow > 1 ? 'flex' : 'block' }}
      />
    </BlockContainer>
  );
};

Logos.defaultProps = {
  containerType: 'headerless',
  groupBy: undefined,
  header: undefined,
  itemsPerRow: undefined,
  mobileItemsPerRow: undefined,
};

Logos.propTypes = {
  _id: PropTypes.string.isRequired,
  containerType: PropTypes.string,
  groupBy: PropTypes.string,
  header: PropTypes.string,
  itemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileItemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logos: logoArrayPropTypes.isRequired,
};

const LogosBlock = (props) => {
  const { query } = props;

  if (query && query.from) {
    return <Query {...query}>{(items) => <Logos {...props} logos={items} />}</Query>;
  }

  return <Logos {...props} />;
};

LogosBlock.defaultProps = {
  containerType: 'headerless',
  groupBy: undefined,
  header: undefined,
  itemsPerRow: undefined,
  mobileItemsPerRow: undefined,
  logos: undefined,
  query: undefined,
};

LogosBlock.propTypes = {
  containerType: PropTypes.string,
  groupBy: PropTypes.string,
  header: PropTypes.string,
  itemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileItemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logos: logoArrayPropTypes,
  query: PropTypes.shape({
    select: PropTypes.object,
    from: PropTypes.shape({
      kind: PropTypes.string,
      collection: PropTypes.string,
    }).isRequired,
  }),
};

export default LogosBlock;
