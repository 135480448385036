// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { SET_ALL, SET_BY_ID } from './sponsors.types';

export const setSponsors = (sponsors) => ({
  type: SET_ALL,
  sponsors,
});

export const setSponsor = (sponsor) => ({
  type: SET_BY_ID,
  sponsor,
});

export function fetchSponsors() {
  return async (dispatch) => {
    const sponsors = await platformService.fetchSponsors();
    dispatch(setSponsors(sponsors));
  };
}
export function fetchSponsor(sponsorId, options = {}) {
  return async (dispatch) => {
    const sponsor = await platformService.fetchSponsor(sponsorId, options);
    dispatch(setSponsor(sponsor));
  };
}
