import React from 'react';
import PropTypes from 'prop-types';
import Flockler from '../components/Flockler/Flockler';

const FlocklerBlock = ({ config }) => (
  <Flockler autoRefresh={config.autoRefresh} projectId={config.projectId} feedId={config.feedId} />
);

FlocklerBlock.propTypes = {
  config: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    feedId: PropTypes.string.isRequired,
    autoRefresh: PropTypes.number,
  }).isRequired,
};

export default FlocklerBlock;
