import cx from 'classnames';
import React from 'react';
import { bem } from '../../../core/design/bem';
import VideoTracker from '../../../core/trackers/VideoTracker';
import { Replay } from '../../../workshops/blocks/ReplayBlock';
import { useWorkshop } from '../../../workshops/store/workshops.hooks';
import BlockContainer from '../../BlockContainer';
import { WorkshopVideoBlockType } from './types';

const css = bem('WorkshopVideoBlock');

const WorkshopVideoBlock = ({
  container,
  field,
  videoProps,
  workshopId,
}: WorkshopVideoBlockType): JSX.Element | null => {
  const item = useWorkshop({ workshopId });
  if (!item) return null;
  const videos = item[field || 'replays'] || [];
  if (!videos || videos.length === 0) return null;

  const replay = videos[0];
  const { value, ...video } = replay;
  const { uri } = value;
  if (!uri) return null;

  return (
    <BlockContainer
      className={cx(css.toString(), 'block--VideoBlock', 'block--WorkshopVideoBlock')}
      header={container?.header || item.title}
      type={container?.type || 'segment'}
    >
      <VideoTracker item={item} video={videos[0]} videoType="replay" />
      <Replay
        icon="play"
        defaultActive
        replay={replay}
        item={item}
        {...videoProps}
        {...video}
        url={uri}
      />
    </BlockContainer>
  );
};

WorkshopVideoBlock.defaultProps = {
  allowFullScreen: true,
  container: undefined,
  field: 'replays',
  videoProps: {},
};

export default WorkshopVideoBlock;
