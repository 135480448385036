import { useSize } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import ImageMapper from 'react-image-mapper';
import BlockContainer from '../../components/BlockContainer';
import { containerTypePropTypes, imageProptypes } from '../../propTypes';
import './ImageWithZones.scss';

const ImageZones = ({ src, zones }) => {
  const ref = useRef();
  const { width } = useSize(ref);
  const map = useMemo(() => {
    return {
      name: 'map',
      areas: (zones || []).map((zone, index) => ({
        name: zone.id || index,
        ...zone,
        coords: (zone.coords || []).map((coord) => coord * width),
      })),
    };
  }, [zones, width]);
  return (
    <div ref={ref}>
      <ImageMapper src={src} map={map} width={width} fillColor="rgba(255, 255, 255, 0.25)" />
    </div>
  );
};

function ImageWithZones(props) {
  const { header, containerType, backgroundImage, zones } = props;
  return (
    <BlockContainer className="block--image-with-zones" type={containerType} header={header}>
      <div style={{ position: 'relative' }}>
        <ImageZones src={backgroundImage?.uri} zones={zones} />
      </div>
    </BlockContainer>
  );
}

ImageWithZones.defaultProps = {
  containerType: 'segment',
  header: undefined,
  backgroundImage: undefined,
  zones: [],
};

ImageWithZones.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  backgroundImage: imageProptypes,
  zones: PropTypes.array,
};

export default ImageWithZones;
