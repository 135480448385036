import React from 'react';
import { replaceValues } from '../../../utils/stringUtils';
import WorkshopLiveBlock from '../../../workshops/blocks/WorkshopLiveBlock';
import { useWorkshop } from '../../../workshops/store/workshops.hooks';
import { WorkshopVideoBlockType } from '../WorkshopVideoBlock/types';

const CMSWorkshopLiveBlock = ({
  container,
  videoProps,
  workshopId,
}: WorkshopVideoBlockType): JSX.Element | null => {
  const item = useWorkshop({ workshopId });
  if (!item) return null;

  return (
    <WorkshopLiveBlock
      className="block--CMSWorkshopLive"
      defaultActive
      allowFullScreen
      item={item}
      containerType={container?.type}
      title={replaceValues(container?.header || item.title, { item })}
      videoProps={videoProps}
    />
  );
};

CMSWorkshopLiveBlock.defaultProps = {
  allowFullScreen: true,
  container: undefined,
  videoProps: {},
};

export default CMSWorkshopLiveBlock;
