import { useInterval } from 'ahooks';
import shuffle from 'lodash/shuffle';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import BlockContainer from '../../components/BlockContainer';
import { cdnImagePropTypes } from '../../components/CdnImage';
import { eventTags } from '../../core/trackers/events';
import { ClickableImage } from '../../headers/blocks/Banner';

function useLoopingCounter(initialValue, count) {
  const [value, setValue] = useState(initialValue);
  const next = useCallback(() => setValue((v) => (v >= count - 1 ? 0 : v + 1)), [setValue, count]);
  return [value, next, setValue];
}

function useRandomItem(items, { interval, shuffle: enableShuffle, loopingStartValue }) {
  // Only works if list doesn't change...
  const randomItems = useMemo(() => (enableShuffle ? shuffle(items) : items), [
    enableShuffle,
    items,
  ]);
  const [index, next] = useLoopingCounter(loopingStartValue || 0, randomItems.length);
  useInterval(next, interval);
  if (!randomItems?.length) return null;
  return randomItems[index];
}

const BannerAds = (props) => {
  const {
    banners,
    blocks,
    containerType,
    header,
    interval,
    trackClick,
    maxWidth,
    maxHeight,
    func,
    loopingStartValue,
    shuffle: enableShuffle,
  } = props;

  const ad = useRandomItem(blocks || banners, {
    interval,
    shuffle: enableShuffle,
    loopingStartValue,
  });

  if (!ad) return null;
  const { link, image } = ad;

  return (
    <BlockContainer className="block--banner-ads" header={header} type={containerType}>
      <ClickableImage
        link={link}
        trackClick={trackClick}
        image={image}
        alt="banner"
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
        fluid
      />
    </BlockContainer>
  );
};

BannerAds.defaultProps = {
  banners: [],
  blocks: undefined,
  containerType: 'headerless',
  header: undefined,
  interval: 10000,
  func: 'crop',
  maxWidth: 260,
  maxHeight: 700,
  loopingStartValue: 0,
  shuffle: true,
  trackClick: eventTags.CTA_GO_TO,
};
BannerAds.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      link: PropTypes.string,
    }),
  ),
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      link: PropTypes.string,
    }),
  ),
  containerType: PropTypes.string,
  interval: PropTypes.number,
  header: PropTypes.string,
  func: cdnImagePropTypes.func,
  loopingStartValue: PropTypes.number,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  shuffle: PropTypes.bool,
  trackClick: PropTypes.string,
};

export default BannerAds;
