import PropTypes from 'prop-types';
import React from 'react';
import Blocks from '../../../../../components/Blocks';
import './ActionsBlock.scss';
import ActionBlock from './components/ActionBlock';
import RegisterButtonBlock from './components/RegisterButtonBlock';

const blockComponents = { action: ActionBlock, register: RegisterButtonBlock };

function ActionsBlock({ blocks }) {
  if (!blocks || blocks.length === 0) return null;
  return (
    <div className="login-block--actions">
      <Blocks blocks={blocks} blockComponents={blockComponents} />
    </div>
  );
}

ActionsBlock.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })).isRequired,
};

export default ActionsBlock;
