import { useInterval } from 'ahooks';
import { useState } from 'react';
import { useTracking } from '../../Context';
import { eventTags } from './events';
import { uuid } from './utils';

const VideoTracker = (props) => {
  const [userVideoSession] = useState(() => uuid(12));
  const { trackEvent } = useTracking();

  useInterval(
    () => trackEvent(eventTags.VIDEO_VIEW_TICK, { ...props, userVideoSession }),
    60 * 1000,
    { immediate: true },
  );
  return null;
};

export default VideoTracker;
