import PropTypes from 'prop-types';
import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Images from '../../../../utils/Images';

const ImageLightbox = (props) => {
  const { images, currentIndex, onClose, components, modalProps, carouselProps } = props;
  const elements = images.map((image) => ({
    ...image,
    source: {
      download: image.uri,
      regular: Images.maxHeight(image.uri, 800),
      fullscreen: Images.maxHeight(image.uri, 1000),
      thumbnail: Images.square(image.uri, 32),
    },
    caption: image.title || '',
  }));
  return (
    <ModalGateway>
      <Modal allowFullscreen closeOnBackdropClick={false} onClose={onClose} {...modalProps}>
        <Carousel
          currentIndex={currentIndex}
          components={components}
          views={elements}
          {...carouselProps}
        />
      </Modal>
    </ModalGateway>
  );
};

ImageLightbox.defaultProps = {
  carouselProps: {},
  components: undefined,
  currentIndex: undefined,
  modalProps: {},
};

ImageLightbox.propTypes = {
  carouselProps: PropTypes.object,
  components: PropTypes.object,
  currentIndex: PropTypes.number,
  modalProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, uri: PropTypes.string }))
    .isRequired,
};

export default ImageLightbox;
