import React, { FC } from 'react';
import { useBoolean } from 'ahooks';
import { Button, Icon, SemanticICONS, SemanticSIZES } from 'semantic-ui-react';
import cx from 'classnames';
import noop from 'lodash/noop';
import SocialNetworkModal from './SocialNetworkModal';

type ShareSocialNetworkButtonProps = {
  buttonIcon?: SemanticICONS;
  buttonLabel: string;
  className?: string;
  hashtag?: string;
  hashtags?: Array<string>;
  iconSize?: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onShare: (type: string) => void;
  shareTitle: string;
  shareUrl: string;
  size?: SemanticSIZES;
  style: any;
};

const ShareSocialNetworkButton: FC<ShareSocialNetworkButtonProps> = (props) => {
  const {
    buttonIcon = 'share',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buttonLabel = 'Share',
    className,
    hashtag,
    hashtags,
    iconSize,
    onClick = noop,
    onShare = noop,
    size,
    shareTitle,
    shareUrl,
    style,
  } = props;
  const [showModal, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

  return (
    <>
      <Button
        size={size}
        className={cx('action', className)}
        onClick={(e) => {
          openModal();
          onClick(e);
        }}
        labelPosition={buttonLabel ? 'right' : undefined}
        style={style}
        icon
      >
        {buttonLabel}
        <Icon name={buttonIcon} style={{ backgroundColor: 'transparent' }} />
      </Button>
      {showModal && (
        <SocialNetworkModal
          onClick={onShare}
          iconSize={iconSize}
          hashtag={hashtag}
          hashtags={hashtags}
          onClose={closeModal}
          shareTitle={shareTitle}
          shareUrl={shareUrl}
        />
      )}
    </>
  );
};

export default ShareSocialNetworkButton;
