import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useScreensConfig } from '../../config/screens.context';
import { searchAsWordList } from '../../core/filter/utils';
import { getString } from '../../utils';
import { replaceValues } from '../../utils/stringUtils';
import { formatStateIntoSearchData, isAValidItem, searchKey } from './EntitySearchBar.helpers';
import SearchBar from './SearchBar';

export const defaultFieldConfig = {
  speakers: {
    root: 'users.collections.speakers.users',
    list: '/speakers?q={searchValue}',
    fields: {
      title: ['firstName', 'lastName'],
      description: 'shortDescription',
      image: 'thumbnail.uri',
    },
  },
  sponsors: {
    root: 'sponsors.sponsors',
    list: '/sponsors?q={searchValue}',
    fields: {
      title: 'name',
      description: 'shortDescription',
      image: 'logo.uri',
    },
  },
  workshops: {
    root: 'workshops.workshops',
    list: '/workshops?q={searchValue}',
    fields: {
      title: 'title',
      description: 'shortDescription',
      image: 'image.uri',
    },
  },
};
const defaultSearchFields = ['title', 'description'];

function EntitySearchBar({ className }) {
  const globalConfig = useScreensConfig();
  const {
    enabled = false,
    entities: fieldsConfig = defaultFieldConfig,
    searchFields = defaultSearchFields,
    maxResultsByEntity = 5,
  } = globalConfig.search ?? {};
  const entities = useSelector(formatStateIntoSearchData(enabled, fieldsConfig, searchFields));
  if (!enabled) return null;

  return (
    <SearchBar
      className={className}
      entries={entities}
      maxResultsByEntity={maxResultsByEntity}
      searchFilter={isAValidItem}
      prepareSearchValue={searchAsWordList}
      moreItem={(config) => {
        if (!fieldsConfig[config.resource]?.list) return null;
        return {
          key: `more-${config.resource}`,
          type: 'more-items',
          title: getString('global-search.see-more'),
          link: replaceValues(fieldsConfig[config.resource]?.list, config),
          description: '',
          [searchKey]: '',
        };
      }}
    />
  );
}
EntitySearchBar.defaultProps = {
  className: undefined,
};
EntitySearchBar.propTypes = {
  className: PropTypes.string,
};

export default React.memo(EntitySearchBar);
