import PropTypes from 'prop-types';
import React from 'react';
import { Divider } from 'semantic-ui-react';

const DividerBlock = ({ color, ...rest }) => (
  <div className="login-block--divider">
    <Divider {...rest} style={{ borderTopColor: color }} />
  </div>
);

DividerBlock.defaultProps = {
  color: undefined,
};

DividerBlock.propTypes = {
  color: PropTypes.string,
};

export default DividerBlock;
