import keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';

export const selectWorkshopsById = createSelector(
  [(state) => state.workshops],
  (workshops) => workshops.workshopsById,
);

export const selectWorkshopsBySlug = createSelector([(state) => state.workshops], (workshops) =>
  keyBy(workshops.workshops, (w) => w.slug || w._id),
);
