/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-extraneous-dependencies
import type { CollectionReference, DocumentReference, SetOptions } from '@firebase/firestore-types';
import { useCallback } from 'react';
import {
  useFirebaseIncrement,
  useFirestoreCollection,
  useFirestoreDocument,
  useFirestoreRef,
  useFirestoreSubRef,
} from '../../../utils/useFirebase';

export namespace OneToOneChat {
  const ROOT_CHATS_COLLECTION = 'chats-one2one';
  const INVITERS_COLLECTION = 'inviters';
  const INVITER_MESSAGES_COLLECTION = 'messages';

  type DocApi<T> = {
    item: T | null;
    ref: DocumentReference<T>;
    remove(): Promise<void>;
    set(data: T, options?: SetOptions): Promise<void>;
    update(data: Partial<T>): Promise<void>;
  };

  type CollectionApi<T> = {
    list: T[];
    ref: CollectionReference<T>;
    create(newOne: T): Promise<void>;
    set(id: string, data: T): Promise<void>;
    update(id: string, data: Partial<T>): Promise<void>;
    remove(id: string): Promise<void>;
  };

  export type SponsorChatApi = DocApi<SponsorChat>;
  export type SponsorChat = {
    open: boolean;
    [key: string]: unknown;
  };

  export type OwnChatApi = DocApi<OwnChat>;
  export type OwnChat = {
    id?: string;
    createdAt: string;
    [key: string]: unknown;
  };

  export type MessagesApi = CollectionApi<Message>;
  export type Message = {
    id?: string;
    createdAt: string;
    emitter: string;
    text: string;
    profile: {
      firstName: string;
      lastName: string;
      isSponsor: boolean;
    };
  };

  export const useChat = (
    eventId: string,
    sponsorCollection: string,
    sponsorId: string,
    userId: string,
    userProfile: Record<'firstName' | 'lastName' | 'thumbnail', string>,
  ) => {
    const increment = useFirebaseIncrement(1);
    const sponsorChatRef = useSponsorChatRef(eventId, sponsorCollection, sponsorId);
    const sponsorChatApi = useFirestoreDocument(sponsorChatRef) as SponsorChatApi;

    const ownChatRef = useOwnChatRef(sponsorChatRef, userId);
    const ownChatApi = useFirestoreDocument(ownChatRef) as OwnChatApi;
    const { set: initChat, update: updateChat } = ownChatApi;

    const messagesApi = useOwnMessages(ownChatRef);
    const { list: messages, create: addRawMessage, remove: removeRawMessage } = messagesApi;

    const addMessage = useCallback(
      async (text: string) => {
        const dateStr = new Date().toISOString();
        if (!messages.length) {
          const data: OneToOneChat.OwnChat = {
            createdAt: dateStr,
            unreadCount: {
              [userId]: 0,
              [sponsorId]: 0,
            },
            lastReadDates: {
              [userId]: dateStr,
              [sponsorId]: dateStr,
            },
            profiles: {
              [userId]: {
                ...userProfile,
              },
            },
          };

          await initChat(data)
            .catch(() => updateChat(data))
            .catch((e) => console.warn(e));
        }

        await addRawMessage({
          createdAt: dateStr,
          emitter: userId,
          text,
          profile: {
            ...userProfile,
            isSponsor: false,
          },
        });

        await updateChat({
          [`unreadCount.${sponsorId}`]: increment,
        });
      },
      [
        messages.length,
        userProfile,
        userId,
        sponsorId,
        initChat,
        addRawMessage,
        updateChat,
        increment,
      ],
    );

    const removeMessage = useCallback(
      async (id: string) => {
        await removeRawMessage(id);
      },
      [removeRawMessage],
    );

    return {
      isAvailable: !!sponsorChatApi.item?.open,
      messages: messagesApi.list,
      addMessage,
      removeMessage,
    };
  };

  const useSponsorChatRef = (eventId: string, sponsorCollection: string, sponsorId: string) => {
    return useFirestoreRef(
      ROOT_CHATS_COLLECTION,
      (chats: CollectionReference) =>
        chats.doc(eventId).collection(sponsorCollection).doc(sponsorId),
      [eventId, sponsorCollection, sponsorId],
    ) as DocumentReference<SponsorChat> | undefined;
  };

  const useOwnChatRef = (sponsorChatRef?: DocumentReference<SponsorChat>, userId?: string) => {
    return useFirestoreSubRef(
      sponsorChatRef,
      (chat: DocumentReference<SponsorChat>) => chat.collection(INVITERS_COLLECTION).doc(userId),
      [userId],
    ) as DocumentReference<OwnChat> | undefined;
  };

  const useOwnMessages = (ownChatRef?: DocumentReference<OwnChat>) => {
    const filter = useCallback(
      (ref?: CollectionReference<Message>) => ref?.orderBy('createdAt', 'desc').limit(200),
      [],
    );

    const baseMessagesRef = useFirestoreSubRef(ownChatRef, (chat: DocumentReference<OwnChat>) =>
      chat.collection(INVITER_MESSAGES_COLLECTION),
    );

    return useFirestoreCollection(baseMessagesRef, filter) as MessagesApi;
  };
}
