import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Message } from 'semantic-ui-react';
import parser from 'ua-parser-js';

function matchesUA(browser, ua) {
  if (!browser?.name) return true; // Matches any browser
  return ua?.browser?.name === browser.name;
}

const BrowserCheckBlock = ({ messages }) => {
  const ua = useMemo(() => parser(), []);

  if (!messages || messages.length === 0) return null;

  const message = messages.find((msg) => matchesUA(msg.browser, ua));
  if (!message) return null;
  const { messageType, ...rest } = message;
  rest[messageType] = true;
  return <Message {...rest} />;
};

BrowserCheckBlock.defaultProps = {
  messages: undefined,
};

BrowserCheckBlock.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      browser: PropTypes.shape({ name: PropTypes.string }),
      messageType: PropTypes.string,
      icon: PropTypes.string,
      header: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
};

export default BrowserCheckBlock;
