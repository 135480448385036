import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'semantic-ui-react';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { workshopProptypes } from '../../propTypes';
import { checkUserAvailability } from '../../utils/agendaUtils';
import Images from '../../utils/Images';
import { getClassName } from '../../workshops/utils';
import EntityLink from '../EntityLink';
import SpeakerAvatars from '../speakers/SpeakerAvatars';
import './ClickableWorkshopCard.scss';
import QuotaField from './QuotaField';
import WorkshopDate from './WorkshopDate';

function ClickableWorkshopCard(props) {
  const { workshop, minHeight, showSpeakers } = props;
  const {
    _id,
    endDate,
    image,
    quota,
    shortDescription,
    speakers,
    startDate,
    title,
    usersCount,
    workshopDuration: duration,
    workshopStatus,
  } = workshop;

  const hasImage = image && image.uri;
  const agenda = useSelector(userAgenda);
  const hasOverlappingEvent = useMemo(() => {
    const otherEvents = agenda.filter((evt) => evt._id !== _id);
    return !checkUserAvailability(otherEvents, workshop);
  }, [agenda, workshop, _id]);

  return (
    <div
      className={cx(
        'workshop--clickable--card',
        getClassName(workshop),
        `session--${workshopStatus}`,
        hasOverlappingEvent && `session--has-overlapping-event`,
      )}
      style={{ minHeight }}
    >
      <Card as={EntityLink} entity={workshop}>
        {hasImage && (
          <div
            className="card__image"
            style={{
              backgroundImage: `url(${Images.cdn(image)})`,
            }}
          >
            {duration && <div className="card__duration">{duration}</div>}
          </div>
        )}
        <Card.Content className="borderless">
          {startDate && (
            <Card.Description className="card__date" style={{ marginTop: 8, marginBottom: 8 }}>
              <WorkshopDate startDate={startDate} endDate={endDate} />
            </Card.Description>
          )}
          <QuotaField quota={quota} usersCount={usersCount} />
          <Card.Header className="card__title" style={{ fontSize: '1.2em', fontWeight: 'normal' }}>
            {title}
          </Card.Header>
          {showSpeakers && <SpeakerAvatars speakerIds={speakers} />}
          {shortDescription && (
            <Card.Description className="card__shortDescription">
              {shortDescription}
            </Card.Description>
          )}
        </Card.Content>
      </Card>
    </div>
  );
}

ClickableWorkshopCard.defaultProps = {
  minHeight: undefined,
};

ClickableWorkshopCard.propTypes = {
  minHeight: PropTypes.number,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default ClickableWorkshopCard;
