import { updateOrAppendItem } from '../../utils/arrayUtils.ts';
import { SET_ALL, SET_BY_ID } from './participants.types';

const initialState = {
  loaded: false,
  participants: [],
};

export default function reduceParticipants(state = initialState, action) {
  switch (action.type) {
    case SET_ALL: {
      return { participants: action.participants, loaded: true };
    }
    case SET_BY_ID: {
      // Update a given sponsor
      const participants = updateOrAppendItem(state.participants, action.participants);
      return { participants };
    }
    default:
      return state;
  }
}
