import React from 'react';
import CdnImage from '../../../../components/CdnImage';
import { imageProptypes } from '../../../../propTypes';

function ImageBlock({ image, ...props }) {
  if (!image) return null;
  return (
    <div className="login-block--image">
      <CdnImage src={image} {...props} />
    </div>
  );
}

ImageBlock.propTypes = {
  image: imageProptypes.isRequired,
};

export default ImageBlock;
