type BaseMenuItem = {
  _id: string;
  path: string;
  name?: string;
  label: string;
  icon?: string;
  color?: string;
};

type MenuItemTypes =
  | 'link'
  | 'page'
  | 'button'
  | 'separator'
  | 'languages'
  | 'search'
  | 'messages'
  | 'notifications';

export type MenuItem = BaseMenuItem & {
  type: MenuItemTypes;
  config?: any; // Extra config (ex : messages)
  children: BaseMenuItem[];
};

type MenuConfig = {
  secondary?: { entries: MenuItem[] };
  bottomTabs?: {
    entries: MenuItem[];
    enabled?: boolean;
    media?: {
      desktop?: boolean;
      mobile?: boolean;
    };
  };
};

type PlatformEndpoints = {
  platform: string;
  document: string;
  files: string;
  meetings: string;
  feedback: string;
  order: string;
  push: string;
  ws: string;
};

type PlatformDesign = Record<string, any>;

export type LanguageOption = {
  key: string;
  value: string;
  text: string;
  url: string;
};

export enum DisconnectionMode {
  POSSIBLE = 'possible',
  IMPOSSIBLE = 'impossible',
  ONLY_FROM_USER_PROFILE = 'only-from-user-profile',
}

export type CookieBannerConfig = {
  enabled?: boolean;
  texts: Record<string, any>;
  services?: string[];
};

export type LiveFeedbackConfig = {
  enabled?: boolean;
};

export type GamificationConfig = {
  enabled?: boolean;
  autoCloseNotification?: boolean;
  websockets?: 'firestore' | 'websockets';
  unlockTimeToleranceMinutes?: number;
};

export type PlatformConfig = {
  // API
  eventId: string;
  basename: string;
  basepath: string;
  domain: string;
  endpoints: PlatformEndpoints;

  // Lang
  lang: string;
  ignoreLanguage?: boolean;
  languages?: LanguageOption[];

  // Menu
  menu: MenuItem[];
  menus: MenuConfig;
  mobileLanguagePosition?: 'bottom' | 'top';

  // Timezone
  timezones?: any[];
  defaultTimezone?: string;

  // Others
  design: PlatformDesign;
  networking?: any;
  options?: { timezone?: { eventTimezone?: string } };
  screens: Record<string, any>;
  strings: Record<string, any>;
  isPlatformClosed?: boolean;
  disconnectionMode?: DisconnectionMode;
  cookieBanner?: CookieBannerConfig;
  refreshConfig?: { disableLinkExpiration?: boolean };
  liveFeedback?: LiveFeedbackConfig;
  gamification: GamificationConfig;
};
