import React from 'react';
import { useDesignConfig } from '../../../../config/design.context';
// import CdnImage from '../../../../components/CdnImage';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CdnImage = require('../../../../components/CdnImage').default;

const LoginBanner = (): JSX.Element | null => {
  const design = useDesignConfig();
  const { banner } = design;
  // eslint-disable-next-line no-undef
  if (!banner) return null;
  return (
    <div className="LoginBanner">
      <a href="/" target="_blank">
        <CdnImage src={banner} alt="salon-banner" maxWidth={1200} />
      </a>
    </div>
  );
};

export default LoginBanner;
