import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { SET_COLLECTION } from './data.types';

const initialState = {
  data: [], // All data, use with care
  dataById: {}, // All collections, use with care
  collections: {},
};

function cleanData(data) {
  return data.map((item) => ({ kind: 'data', ...item }));
}

export default function reduceData(state = initialState, action) {
  switch (action.type) {
    case SET_COLLECTION: {
      const { collection } = action;
      const data = cleanData(action.data);

      // Update all data
      const collectionStore = {
        collection,
        data,
        dataById: keyBy(action.data, '_id'),
      };
      const collections = {
        ...state.collections,
        [collection]: collectionStore,
      };

      const allData = flatten(values(collections).map((col) => col.data));
      const dataById = keyBy(data, '_id');
      return {
        ...state,
        collections,
        data: allData,
        dataById,
      };
    }
    default:
      return state;
  }
}
