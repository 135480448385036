/* eslint-disable @typescript-eslint/no-unused-vars */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import BlockContainer from '../../../components/BlockContainer';
import { useTracking } from '../../../Context';
import { usePlatformMessages } from '../../../core/hooks/messages.hook';
import messagesService from '../../../core/services/messages.service';
import { eventTags } from '../../../core/trackers/events';
import { useIsAnonymous } from '../../../profile/hooks';
import { containerTypePropTypes, workshopProptypes } from '../../../propTypes';
import { showBlacklistError } from '../../../social-wall/socialWallUtils';
import { sweetAlert } from '../../../utils/popupUtils';
import CommentInput from './components/CommentInput';
import WorkshopComments from './components/WorkshopComments';
import './WorkshopCommentsBlock.scss';

const translationPrefix = 'workshops.workshop.comments';

function getCommentGroupId(workshop, linkCommentToWorkshop) {
  const { _id, workshopId } = workshop;
  const commentRefGroupId = get(workshop, ['comments', 'ref', 'groupId']);
  if (commentRefGroupId) return commentRefGroupId;
  if (workshopId) {
    // session
    if (linkCommentToWorkshop) return workshopId;
  }
  return _id;
}

function getCommentCollection(workshop) {
  return get(workshop, ['comments', 'ref', 'collection'], workshop.collection);
}

function WorkshopCommentsBlock(props) {
  const { trackEvent } = useTracking();
  const isAnonymousUser = useIsAnonymous();
  const {
    item: workshop,
    title,
    containerType,
    isAnonymous,
    container,
    showUserOnThumbnailClick,
    maxChars,
    maxComments,
    ...rest
  } = props;
  const { linkCommentToWorkshop = false, disableComments = false } = rest;
  const { t } = useTranslation();
  const [showConnection, setShowConnection] = useState(false);
  const workshopCollection = getCommentCollection(workshop);
  const groupId = getCommentGroupId(workshop, linkCommentToWorkshop);
  const comments = usePlatformMessages('comments', workshopCollection, groupId);

  const onSendComment = async (message) => {
    try {
      if (isAnonymousUser) {
        setShowConnection(true);
        return false;
      }
      const messageData = { message };
      const res = await messagesService.postMessage('comments', messageData, {
        parent: {
          kind: 'workshops',
          collection: workshopCollection,
          id: groupId,
        },
      });
      if (res.errors) {
        showBlacklistError(res, message);
        return false;
      }
      trackEvent(eventTags.POST_COMMENT, { item: workshop });
      return true;
    } catch (e) {
      sweetAlert({ icon: 'error', title: 'Une erreur est survenue' });
      return false;
    }
  };
  return (
    <BlockContainer
      className={cx('workshop--commentsBlock', { anonymous: isAnonymous })}
      header={title}
      type={containerType}
      {...container}
    >
      <Header as="h3">
        {comments.length} {t(`${translationPrefix}.comments`)}
      </Header>
      {!disableComments && (
        <CommentInput
          onSend={onSendComment}
          isAnonymous={isAnonymous || isAnonymousUser}
          maxChars={maxChars}
        />
      )}
      <WorkshopComments
        comments={comments}
        workshopId={groupId}
        isAnonymous={isAnonymous}
        showUserOnThumbnailClick={showUserOnThumbnailClick}
        maxChars={maxChars}
        maxComments={maxComments}
      />
      {showConnection && <UserRegistrationModal onClose={() => setShowConnection(false)} />}
    </BlockContainer>
  );
}

WorkshopCommentsBlock.defaultProps = {
  container: {},
  containerType: 'segment',
  isAnonymous: false,
  maxChars: undefined,
  maxComments: 500,
  showUserOnThumbnailClick: false,
  title: '',
};

WorkshopCommentsBlock.propTypes = {
  container: PropTypes.object,
  containerType: containerTypePropTypes,
  isAnonymous: PropTypes.bool,
  item: PropTypes.shape(workshopProptypes).isRequired,
  maxChars: PropTypes.number,
  maxComments: PropTypes.number,
  showUserOnThumbnailClick: PropTypes.bool,
  title: PropTypes.string,
};

export default WorkshopCommentsBlock;
