import React from 'react';

export function linkable(Component) {
  return ({ href, onClick, ...otherProps }) => {
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    if (href) {
      return (
        <Component
          as="a"
          href={href}
          target="_blank"
          rel="noreferrer noopener"
          onClick={(e) => {
            e.stopPropagation(); // Link clicked...
            if (onClick) onClick(e);
          }}
          {...otherProps}
        />
      );
    }
    return <Component onClick={onClick} {...otherProps} />;
  };
}
