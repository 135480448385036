import get from 'lodash/get';
import trim from 'lodash/trim';

import store from '../../shared/Store';

/**
 * trackEvent for Tag Commander.
 * Hacky implementation, it creates a button, sets the necessary tags on it,
 * then triggers a click to simulate an action...
 * TODO : see with Fadi if we can do better
 * @param {*} event
 */
export function trackEvent(event) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('tagCommanderTrack', event.tag);
  }
  const el = document.createElement('button');
  el.setAttribute('data-tc-clic', event.tag);
  el.style.display = 'none';

  // To have clicks register, append to body, click, and remove
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}

function injectVariable(template, data) {
  // TODO: clean function, sanitize, working, optimize, handle edge case (multiple template variable)
  return template.replace(/\${([^}]*)}/g, (m, key) => get(data, key));
}

export function trackPage(locationPath, page) {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  if (window.__DATA__.tagCommander) {
    const currentPageTc = page.tc;

    if (currentPageTc) {
      Object.keys(currentPageTc).forEach((key) => {
        window.tc_vars[key] = injectVariable(currentPageTc[key], {
          path: trim(locationPath, '/').replace(/\//gm, '::') || 'home',
          ...store,
        });
      });
    }
    if (window.tC) {
      window.tC.event.spa_pageLoad();
      window.tC.container.reload();
    } else {
      // eslint-disable-next-line no-console
      console.error('Missing window.tC');
    }
  }
}
