import React from 'react';
import { Item } from './types';

const LiStyles = React.memo(({ _id, items }: { _id: string; items: Item[] }) => {
  const css = items
    .map((item, index) => {
      const { liStyle } = item;
      if (!liStyle?.backgroundColor) return '';
      return `.CarouselBlock--id-${_id} li:nth-child(${index + 1}) { background-color: ${
        liStyle.backgroundColor
      }}`;
    })
    .join('\n')
    .trim();
  if (!css) return null;
  return (
    <style
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: css }}
    />
  );
});

export default LiStyles;
