import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Button, Image } from 'semantic-ui-react';
import { userProptypes } from '../propTypes';
import avatar from '../assets/avatar-neutre.png';
import EntityLink from './EntityLink';
import SquareImage from './SquareImage';

function UserBubble(props) {
  const defaultUser = useSelector((state) => state.user.user);
  const { user = defaultUser, isAnonymous, as, entity } = props;
  const { firstName, lastName, thumbnail } = user || {};
  if (isAnonymous || (!firstName && !lastName)) {
    return <Image src={avatar} style={{ maxHeight: 40 }} circular />;
  }

  if (thumbnail) {
    if (as && entity) {
      return (
        <EntityLink entity={entity}>
          <SquareImage className="user-bubble" src={thumbnail} imageSize={40} circular />
        </EntityLink>
      );
    }
    return <SquareImage className="user-bubble" src={thumbnail} imageSize={40} circular />;
  }

  return (
    <Button
      as={as}
      entity={entity}
      className="user-bubble"
      circular
      basic
      style={{
        padding: '8px',
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {firstName?.substring(0, 1)}
      {lastName?.substring(0, 1)}
    </Button>
  );
}
UserBubble.defaultProps = {
  as: undefined,
  isAnonymous: false,
  entity: undefined,
  user: undefined,
};

UserBubble.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  as: PropTypes.any,
  isAnonymous: PropTypes.bool,
  entity: PropTypes.object,
  user: PropTypes.shape(userProptypes),
};

export default UserBubble;
