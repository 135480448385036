const genericConstraints = {
  simple: [{ type: 'length', min: 8 }],
  complex: [
    { type: 'length', min: 12 },
    { type: 'lowercase', min: 1 },
    { type: 'uppercase', min: 1 },
    { type: 'digits', min: 1 },
    { type: 'special', min: 1 },
  ],
};

const regexMap = {
  lowercase: /[a-z]/g,
  uppercase: /[A-Z]/g,
  digits: /[0-9]/g,
  special: /[^a-zA-Z0-9]/g,
};

const count = (str, regex) => {
  return ((str || '').match(regex) || []).length;
};

export function normalizeString(string) {
  if (!string) return '';
  return string.trim();
}

export function checkPasswordValidity(password, length = 8) {
  return !(!password || password.length < length);
}

export function checkPasswordsSimilarity(password, confirmPassword) {
  return normalizeString(password) === normalizeString(confirmPassword);
}

export function checkConstraint(password, constraint) {
  if (!password) return false;

  const { type, min = 1 } = constraint;
  switch (type) {
    case 'lowercase':
    case 'uppercase':
    case 'digits':
    case 'special': {
      const regex = regexMap[type];
      return count(password, regex) >= min;
    }
    case 'length': {
      return checkPasswordValidity(password, min);
    }
    default:
      return false;
  }
}

export function validateConstraints(password, confirmPassword, constraints) {
  return [
    ...constraints.map((c) => ({ ...c, isValid: checkConstraint(password, c) })),
    {
      type: 'same-password',
      isValid: password && password !== '' && password === confirmPassword,
    },
  ];
}

export function ensureConstraint(constraints) {
  if (Array.isArray(constraints)) return constraints;
  if (typeof constraints === 'string')
    return genericConstraints[constraints] || genericConstraints.simple;
  return genericConstraints.simple;
}
