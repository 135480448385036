/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Header, Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import useTranslations from '../../../hooks/useTranslations';
import store from '../../../shared/Store';
import { replaceUriValues, replaceValues } from '../../../utils/stringUtils';

const { endpoints, eventId } = window.__DATA__;
const translationPrefix = 'blocks.video-chat-rooms';

const videoBlock = bem('video-chat-room-block');

function generateUrl(room) {
  const { participantUri, account = 'meetings', roomId } = room;

  if (roomId) {
    // eslint-disable-next-line max-len
    return `${endpoints.meetings}/cm/a/${account}/meeting/${roomId}/redirect?nickname={firstName}%20{lastName}&email={email}`;
  }
  return participantUri;
}

const AccesssButtonList = ({ className, rooms }) => {
  const { t } = useTranslations(translationPrefix);

  // TODO : use useTracking ?

  return rooms.map((room) => {
    const { name } = room;
    return (
      <>
        {name && (
          <p>
            <strong>{t('theme')}</strong>
            <br />
            {name}
          </p>
        )}
        <Button
          className={className}
          icon
          fluid
          href={replaceUriValues(generateUrl(room), { ...store.user })}
          labelPosition="right"
          primary
          style={{ width: '100%', backgroundColor: '#22ba46', marginBottom: 10 }}
          target="_blank"
        >
          <Icon name="play" />
          {t('access-room')}
        </Button>
      </>
    );
  });
};

const VideoChatRoomBlock = (props) => {
  const { item, field, strings } = props;
  const { t } = useTranslations();

  const roomList = item[field];

  if (!roomList || roomList.length === 0) {
    return null;
  }

  const { header = 'Video Chat rooms', description } = strings;

  return (
    <Card className={videoBlock().toString()}>
      <Card.Content>
        <Header as="h3">{header}</Header>
      </Card.Content>
      <Card.Content className={videoBlock('button-container').toString()}>
        {description && (
          <p className={videoBlock('button-container', { description: true }).toString()}>
            {description}
          </p>
        )}
        <AccesssButtonList
          className={videoBlock('button-container', { 'access-list': true }).toString()}
          rooms={roomList}
        />
      </Card.Content>
    </Card>
  );
};

VideoChatRoomBlock.propTypes = {
  field: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  strings: PropTypes.shape({
    description: PropTypes.string,
    header: PropTypes.string.isRequired,
  }).isRequired,
};

export default VideoChatRoomBlock;
