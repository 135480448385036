import { apple, google, outlook } from './icons';

export const SHARE_SITES = {
  GOOGLE: 'Google',
  ICAL: 'iCal',
  OUTLOOK: 'Outlook',
  YAHOO: 'Yahoo',
};

export const sites = {
  [SHARE_SITES.GOOGLE]: {
    label: SHARE_SITES.GOOGLE,
    icon: google,
  },
  [SHARE_SITES.OUTLOOK]: {
    label: SHARE_SITES.OUTLOOK,
    icon: outlook,
  },
  [SHARE_SITES.ICAL]: {
    label: SHARE_SITES.ICAL,
    icon: apple,
  },
};
