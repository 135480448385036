import React from 'react';
import get from 'lodash/get';
import endsWith from 'lodash/endsWith';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { fileType } from '../core/platformPropTypes';
import './FileList.scss';
import CdnImage from './CdnImage';

export function hasValidUri(file) {
  return !!file.value?.uri;
}

function isPDF(filename) {
  return filename && endsWith(filename.toLowerCase(), '.pdf');
}

const FileThumbnail = ({ file, showThumbnail }) => {
  const { value } = file;

  if (!value) return null;
  if (showThumbnail && isPDF(value.originalFilename || value.uri)) {
    return (
      <CdnImage
        alt={value.originalFilename}
        src={value.uri}
        maxWidth={200}
        maxHeight={400}
        imageType="jpg"
        cdnOptions={{ bg_colour: 'fafafa' }}
      />
    );
  }

  return <List.Icon name="file pdf outline" size="large" verticalAlign="middle" />;
};

FileThumbnail.defaultProps = {
  showThumbnail: false,
};

FileThumbnail.propTypes = {
  file: fileType.isRequired,
  showThumbnail: PropTypes.bool,
};

const FileList = ({ files, showThumbnail, showDescription, onDownload }) => (
  <List divided relaxed className="list--files">
    {files.map((file) => {
      const title = file.title || get(file, 'value.originalFilename') || '';
      return (
        <List.Item
          key={file._id}
          as="a"
          href={file.value ? file.value.uri : ''}
          target="_blank"
          alt="title"
          onClick={onDownload ? () => onDownload(file) : undefined}
        >
          <FileThumbnail file={file} showThumbnail={showThumbnail} />
          <List.Content>
            <List.Header>{title}</List.Header>
            {showDescription && <List.Description>{file.description}</List.Description>}
          </List.Content>
        </List.Item>
      );
    })}
  </List>
);

FileList.defaultProps = {
  onDownload: undefined,
  showThumbnail: false,
  showDescription: true,
};

FileList.propTypes = {
  files: PropTypes.arrayOf(fileType).isRequired,
  onDownload: PropTypes.func,
  showThumbnail: PropTypes.bool,
  showDescription: PropTypes.bool,
};

export default FileList;
