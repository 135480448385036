import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { searchAsWordList } from '../../../core/filter/utils';
import useTranslations from '../../../hooks/useTranslations';
import { replaceValues } from '../../../utils/stringUtils';
import { defaultFieldConfig } from '../../SearchBar/EntitySearchBar';
import {
  formatStateIntoSearchData,
  isAValidItem,
  searchKey,
} from '../../SearchBar/EntitySearchBar.helpers';
import SearchBar from '../../SearchBar/SearchBar';
import './MenuSearchBar.scss';
// import { formatStateIntoSearchData, isAValidItem, searchKey } from './EntitySearchBar.helpers';
// import SearchBar from './SearchBar';

const defaultSearchFields = ['title', 'description'];

function MenuSearchBar({ className, searchConfig }) {
  const { t } = useTranslations();
  // const globalConfig = useScreensConfig();
  const {
    entitiesToSearch = defaultFieldConfig,
    searchFields = defaultSearchFields,
    maxResultsByEntity = 5,
  } = searchConfig || {};
  // const selector = useMemo(
  //   () => createSelector(formatStateIntoSearchData(true, fieldsConfig, searchFields)),
  //   [fieldsConfig, searchFields],
  // );
  const entities = useSelector(formatStateIntoSearchData(true, entitiesToSearch, searchFields));
  // useEffect(() => {
  //   // TODO: optimize
  //   console.log('MenuSearchBar.has changed');
  // }, [entities]);
  // console.log('MenuSearchBar');
  // console.log('entities', entities);
  // if (!enabled) return null;

  // return <Search input={{ icon: 'search', iconPosition: 'left' }} />;

  // eslint-disable-next-line no-unreachable
  return (
    <SearchBar
      className={className}
      entries={entities}
      maxResultsByEntity={maxResultsByEntity}
      searchFilter={isAValidItem}
      prepareSearchValue={searchAsWordList}
      searchProps={{ input: { icon: 'search', iconPosition: 'left' } }}
      moreItem={(config) => {
        const { url, label } = entitiesToSearch[config.resource]?.seeMore || {};
        if (!url) return null;

        return {
          key: `more-${config.resource}`,
          type: 'more-items',
          title: label || t('global-search.see-more'),
          link: replaceValues(url, config),
          description: '',
          [searchKey]: '',
        };
      }}
    />
  );
}
MenuSearchBar.defaultProps = {
  className: undefined,
  searchConfig: undefined,
};
MenuSearchBar.propTypes = {
  className: PropTypes.string,
  searchConfig: PropTypes.shape({
    entities: PropTypes.object,
    searchFields: PropTypes.array,
    maxResultsByEntity: PropTypes.number,
  }),
};

export default React.memo(MenuSearchBar);
