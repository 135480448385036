import keyBy from 'lodash/keyBy';
import { updateOrAppendItem } from '../../utils/arrayUtils.ts';
import { SET_ALL, SET_BY_ID } from './sponsors.types';

const initialState = {
  sponsors: [],
  sponsorsById: {},
};

function ensureKind(sponsor) {
  if (!sponsor.kind) return { ...sponsor, kind: 'users' };
  return sponsor;
}

export default function reduceSponsors(state = initialState, action) {
  switch (action.type) {
    case SET_ALL: {
      const sponsors = action.sponsors.map(ensureKind);
      return {
        sponsors,
        sponsorsById: keyBy(sponsors, '_id'),
      };
    }
    case SET_BY_ID: {
      // Update a given sponsor
      const sponsors = updateOrAppendItem(state.sponsors, ensureKind(action.sponsor));
      return { sponsors, sponsorsById: keyBy(sponsors, '_id') };
    }
    default:
      return state;
  }
}
