import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { useDesignConfig } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import { useMe } from '../../../../profile/hooks';
import { replaceValues } from '../../../../utils/stringUtils';
import SquareImage from '../../../SquareImage';
import './SidebarUser.scss';

const css = bem('sidebar-user');

type SidebarUserProps = {
  color?: string;
  titleTemplate?: string;
  subtitleTemplate?: string;
  style?: any;
};

const SidebarUser = ({
  color,
  titleTemplate,
  subtitleTemplate,
  style,
}: SidebarUserProps): JSX.Element | null => {
  const { primaryColor } = useDesignConfig();
  const me = useMe();
  if (!me || me.userType === 'anonymous') return null;
  const { thumbnail } = me;
  return (
    <Menu.Item
      className={css.toString()}
      style={{
        backgroundColor: color === 'primary' ? primaryColor : color,
        color: color ? 'white' : undefined,
        ...style,
      }}
    >
      {!thumbnail && (
        <div>
          <Icon name="user circle" />
        </div>
      )}
      {thumbnail && (
        <div className="user-thumbnail">
          <SquareImage src={thumbnail} imageSize={64} srcSet circular />
        </div>
      )}
      <div className="user-title">
        {replaceValues(titleTemplate || '{firstName} {lastName}', me)}
      </div>
      <div className="user-subtitle">{replaceValues(subtitleTemplate || '{email}', me)}</div>
    </Menu.Item>
  );
};

SidebarUser.defaultProps = {
  color: 'primary',
  titleTemplate: '{firstName} {lastName}',
  subtitleTemplate: '{email}',
  style: undefined,
};

export default SidebarUser;
