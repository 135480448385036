import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Item } from 'semantic-ui-react';
import EntityLink from '../EntityLink';
import ExpiringNavLink from '../ExpiringNavLink';
import SquareImage from '../SquareImage';
import { bem } from '../../core/design/bem';
import WorkshopDate from '../workshops/WorkshopDate';

const css = bem('SearchResultItem');

const SearchResult = ({ type, image, title, description, item, link }) => {
  if (type === 'more-items') {
    return (
      <Dropdown.Item
        className={css({ 'more-items': true }).toString()}
        style={{ display: 'flex', color: 'inherit' }}
        as={ExpiringNavLink}
        to={link}
      >
        <Item.Content verticalAlign="middle">
          <Item.Header>{title}</Item.Header>
        </Item.Content>
      </Dropdown.Item>
    );
  }

  const { collection, color, startDate, endDate } = item;
  const squareImageFunc = collection === 'speakers' ? 'crop' : 'fit';

  return (
    <Dropdown.Item
      className={css({
        kind: item.kind,
        collection: item.collection,
        category: item.category,
      }).toString()}
      style={{ display: 'flex', color: 'inherit', borderLeft: `15px solid ${color}` }}
      as={EntityLink}
      entity={item}
    >
      {image && (
        <SquareImage
          src={image}
          imageSize={56}
          func={squareImageFunc}
          style={{
            width: '4em',
            height: '4rem',
            maxHeight: 'none',
            flex: '0 0 auto',
          }}
        />
      )}

      <Item.Content verticalAlign="middle">
        <Item.Header>{title}</Item.Header>
        {description && <Item.Description>{description}</Item.Description>}
        <WorkshopDate startDate={startDate} endDate={endDate} showEndTime />
      </Item.Content>
    </Dropdown.Item>
  );
};
SearchResult.defaultProps = {
  image: undefined,
  description: undefined,
  link: undefined,
  type: undefined,
};
SearchResult.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  description: PropTypes.node,
  image: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(['more-items']),
};

export default SearchResult;
