const modalEntriesReducer = (state = { items: {} }, action) => {
  switch (action.type) {
    case 'SET_MODAL_ENTRIES': {
      const { kind, collection, items } = action;
      return {
        ...items,
        [kind]: {
          ...items[kind],
          [collection]: items,
        },
      };
    }
    default:
      return state;
  }
};

export function setModalEntries(kind, collection, items) {
  return {
    type: 'SET_MODAL_ENTRIES',
    kind,
    collection,
    items,
  };
}

export default modalEntriesReducer;
