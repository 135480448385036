/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { getString } from '../../utils';

import Chat from './Chat';
import './FloatingChat.scss';

const FloatingChat = ({ title, popupDelay, ...chatProps }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = !popupDelay
      ? null
      : setTimeout(() => {
          setOpen(true);
        }, popupDelay);

    return () => clearTimeout(timer);
  }, [popupDelay]);

  return (
    <section
      className={cx('FloatingChat', {
        'FloatingChat-opened': open,
        'FloatingChat-closed': !open,
      })}
    >
      <button type="button" className="FloatingChat__Bar" onClick={() => setOpen((v) => !v)}>
        <Icon name="comment outline" style={{ margin: 0 }} />
        <span style={{ marginLeft: 8 }}>{title}</span>
        <div style={{ flex: 1 }} className="space" />
        <Icon className="FloatingChat__ToggleButton" name="angle up" />
      </button>
      <Chat {...chatProps} />
    </section>
  );
};
FloatingChat.defaultProps = {
  title: getString('floatingChat.defaultTitle'),
  popupDelay: 0,
};
FloatingChat.propTypes = {
  ...Chat.propTypes,
  title: PropTypes.string,
  popupDelay: PropTypes.number,
};

export default FloatingChat;
