import PropTypes from 'prop-types';
import React from 'react';
import Blocks from '../../../../components/Blocks';
import './LinksBlock.scss';

const Link = ({ text, url }) => {
  return (
    <a className="login-block--link" href={url}>
      {text}
    </a>
  );
};

Link.defaultProps = {};

Link.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const blockComponents = { link: Link };

function LinksBlock({ blocks }) {
  if (!blocks || blocks.length === 0) return null;
  return (
    <div className="login-block--links">
      <Blocks blocks={blocks} blockComponents={blockComponents} />
    </div>
  );
}

LinksBlock.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })).isRequired,
};

export default LinksBlock;
