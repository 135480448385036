import { useSelector } from 'react-redux';

export const useCurrentUser = () => {
  return useSelector((state) => {
    return state.user.user;
  });
};

export const useCurrentUserId = () => {
  const user = useCurrentUser();
  return user?._id;
};
