import { arrayOf, shape, string, object } from 'prop-types';

export const fileType = shape({
  title: string,
  description: string,
  value: shape({ uri: string, originalFilename: string }),
});

export const cmsBlocksType = shape({
  blocks: arrayOf(object),
});

export const orderByType = arrayOf(shape({ field: string, order: string }));
