import trim from 'lodash/trim';
import React from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

function nl2br(str?: string): string | (string | JSX.Element)[] {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return trim(str, '\n\r')
    .split(newlineRegex)
    .map((line, index) => {
      if (line.match(newlineRegex)) {
        // eslint-disable-next-line react/no-array-index-key
        return <br key={index} />;
      }
      return line;
    });
}

export default nl2br;
