import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { replaceValues } from '../../utils/stringUtils';
import { Meta, outputMetadata } from './utils';
// import { AppContext } from './Context';
// import { getCurrentPath } from './utils/pages';
// import { metaType } from './utils/propTypes';

type SiteMetaProps = {
  meta?: Meta[];
};

/**
 * Site-level metadata
 */
const SiteMeta = ({ meta = [] }: SiteMetaProps): JSX.Element => (
  <Helmet>
    <meta property="og:type" content="website" />
    {/* TODO <meta property="og:locale" content="en_GB" /> */}
    {meta.map(outputMetadata)}
  </Helmet>
);

SiteMeta.defaultProps = {
  meta: [],
};

function hasTitle(meta?: Meta[]) {
  if (!meta) return false;
  return meta.find((m) => m.type === 'meta' && m.name === 'title' && m.content);
}

// function renderAlternateLink(rooturl, page, lang) {
//   const currentPath = getCurrentPath(rooturl, page, lang);
//   return (
//     <link key={`alternate-${lang.name}`} rel="alternate" hrefLang={lang.name} href={currentPath} />
//   );
// }

type PageMetaProps = {
  meta?: Meta[];
  title?: string;
  item?: any;
};

const PageMeta = ({ meta = [], title, item }: PageMetaProps): JSX.Element | null => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <meta property="og:url" content={window.location.href} />
      {title && !hasTitle(meta) ? (
        <title>{replaceValues(title, { item }) || t('title')}</title>
      ) : undefined}
      {meta.map((m) => outputMetadata(m, item))}
    </Helmet>
  );
};

PageMeta.defaultProps = {
  item: undefined,
  meta: [],
  title: undefined,
};

export default PageMeta;
