import keyBy from 'lodash/keyBy';
import { SET, LOADING } from './agenda.types';

const initialState = {
  loading: false,
  lastLoadTime: null,
  agenda: [],
  agendaById: {},
};

export default function reduceAgenda(state = initialState, action) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET: {
      return {
        loading: false,
        lastLoadTime: Date.now(),
        agenda: action.agenda,
        agendaById: keyBy(action.agenda, '_id'),
      };
    }
    default:
      return state;
  }
}
