import PropTypes from 'prop-types';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import CdnImage from '../CdnImage';
import './WorkshopLogos.scss';

function WorkshopLogos({ logos, maxWidth, maxHeight }) {
  if (!logos || logos.length === 0) return null;
  return (
    <div className="logos">
      {logos.map((logo) => {
        const { _id: logoId, uri, title: logoTitle } = logo;
        const imageComponent = (
          <CdnImage
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            key={logoId}
            src={uri}
            style={{ width: maxWidth, height: maxHeight, objectFit: 'contain' }}
            func="fit"
          />
        );
        if (!logoTitle) return imageComponent;
        return <Popup key={logoId} content={logoTitle} trigger={imageComponent} />;
      })}
    </div>
  );
}

WorkshopLogos.defaultProps = {
  maxHeight: 40,
  maxWidth: 80,
  logos: undefined,
};

WorkshopLogos.propTypes = {
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  logos: PropTypes.arrayOf(PropTypes.object),
};

export default WorkshopLogos;
